

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, FormHelperText, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks, DocumentDialogCommonHooks } from '../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../helpers/table/react-material-table'
import { API } from '../../../../services/api-config'
import ConfirmationModel from '../../../../helpers/modal/confirm-modal'
import { KSDocumentSearchIcon, KSPencilAltIcon, KSTrashIcon } from '../../../../icons/custome-icon'

import { getApiCallDynamic, postApiCallDynamicWithOutReturn } from '../../../../services/api-service'
import { UPDATE } from '../../../../constants/constatnt-variable'
import { kstheme } from '../../../../theme/theme'
import SupportingDocumentForm from './supporting-doc-form'
import { previewDocument } from '../../../../helpers/preview-document'
import { TaskContext } from '../../taskContext/TaskContext'





export default function SupportingDocument({ supportingDocs, setSupportingDocs, formId, documentTypes }) {


    const { taskState, insertSupportingDoc, deleteSupportingDoc, updateSupportingDoc, clearSupportingDocs } = useContext(TaskContext);

    const { borrowerTask, contextSupportingDocs } = taskState;


    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

    const route = useNavigate()
    const location = useLocation();

    let [formValue, setFormValue] = useState([])



    let [docList, setDocList] = useState(contextSupportingDocs)
    let [localData, setLocalData] = useState(null)








    useEffect(() => {
        console.log("hihihihlocaldata " + JSON.stringify(localData, null, 2))
        if (localData !== null) {
            // setSupportingDocs(prevDocs => [...prevDocs, localData]);


            //const updatedDocs = [...contextSupportingDocs, localData];

            // Update the context with the new array
            insertSupportingDoc(localData);
            setLocalData(null);
        }
        console.log("hihihihlocaldatasupport " + JSON.stringify(supportingDocs, null, 2))


        //updateBorrowerTask([...borrowerTask, localData])


    }, [localData]);

    console.log(" Updated Context Supporting Task " + JSON.stringify(contextSupportingDocs, null, 2))
    console.log(" Updated Local Task " + JSON.stringify(localData, null, 2))

    let [customError, setCustomerror] = useState("")

    const [editState, setEditState] = useState(() => {
        return null
    })


    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(docList)
            let editItem = list.find((item, i) => i == editState)
            setDocEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    // useEffect(() => {
    //     if (deleteState != null) {
    //         docList.splice(deleteState, 1)
    //         setDocList([...docList])
    //         setDeleteState(null)

    //     }

    // }, [deleteState])

    // delete table
    // useEffect(() => {
    //     if (deleteState != null) {
    //         // Remove the document from both docList and supportingDocs
    //         const updatedDocList = docList.filter((_, index) => index !== deleteState);
    //         setDocList(updatedDocList);
    //         setSupportingDocs(updatedDocList); // Update the supportingDocs prop as well
    //         setDeleteState(null);
    //     }
    // }, [deleteState]);

    useEffect(() => {
        if (deleteState !== null) {



            const updatedDocList = supportingDocs.filter((_, index) => index !== deleteState);


            setDocList(updatedDocList);


            setSupportingDocs(updatedDocList);


            setDeleteState(null);
        }
    }, [deleteState]);




    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "fileName",
                header: "Document Name",
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            maxWidth: '300px',
                        }}
                    >
                        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                            {row?.original['fileName']}
                        </Typography>
                        {row?.original['blankDocument'] && (
                            <Typography variant="body2" sx={{ color: '#D94166' }}>
                                Blank form
                            </Typography>
                        )}

                    </Box>
                ),
            },
            {
                accessorKey: "documentName",
                header: "Document Type",
                enableColumnActions: false,

            },



        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [docEdit, setDocEdit] = useState();
    const handleClickOpen = () => {

        setDocEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)

    };



    const handleCloseDelete = (event, row) => {
        console.log(" Delete ROOO " + JSON.stringify(row, null, 2)

        )


        if (event.target.value == 'yes') {
            //  setDeleteState(row.index)
            deleteSupportingDoc(
                row?.original?.docId ? 'docId' : 'documentName',
                row?.original?.docId ? row?.original?.docId : row?.original?.documentName // Value to match
            );

        }
    }



    const docsSubmit = (data) => {
        setCustomerror("")
        if (editState != null) {
            docList[editState] = {
                ...docList[editState],
                ...data
            }

            setEditState(null)
        } else {
            docList.push(data);
        }

        setDocList([...docList]);
        handleClose()
    }
    useEffect(() => {
        setDocList([...docList]);
    }, [docList.length])




    const [docListLibrary, setDocListLibrary] = useState([]);

    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTaskDoc,
                // param: { formId: formId, status: status }, 
            }).then((res) => {
                if (res?.status === 200) {
                    setDocListLibrary(res?.data);
                    //  updateBorrowerTask(res?.data)
                    updateThirdPartyTask(res?.data) // rkreview
                    console.log(" This is " + JSON.stringify(res.data, null, 2))
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, []);




    return (
        <>




            <CustomReactMaterialTable
                staticLoad={true}
                staticData={contextSupportingDocs}
                columns={tableColumn}
                enablePagination={false}
                enableBottomToolbar={false}
                enableSorting={false}
                enableToolbarInternalActions={false}
                renderTopToolbarCustomActions={() => (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mr: isMobile ? '0rem' : '1rem', mt: isMobile ? '0.5rem' : '1rem' }}>
                        <Button variant="outlined" onClick={handleClickOpen}>
                            Add
                        </Button>
                    </Box>
                )}
                enableRowActions={true}
                renderRowActions={
                    ({ row }) => {

                        return (
                            <Stack direction={'row'}>
                                {/* <IconButton onClick={() => { setEditState(row.index) }}>
                                            <KSPencilAltIcon />
                                        </IconButton> */}
                                <IconButton
                                    color="primary"
                                    onClick={() => previewDocument(row?.original)}  // Pass the row data when clicked
                                    disabled={!row?.original?.fileName}
                                >
                                    <KSDocumentSearchIcon />
                                </IconButton>

                                {/* <ConfirmationModel
                                            body={<IconButton
                                                id={row.index} onClick={() => {
                                                    setDeleteState(row.index)
                                                }}>
                                                <KSTrashIcon />
                                            </IconButton>}
                                            message={`Are you sure want remove  ${row?.original?.documentType} ?`}
                                            title={'Delete Document'}
                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                        /> */}
                                <ConfirmationModel
                                    body={<IconButton
                                        onClick={() => {
                                            setDeleteState(row.index);
                                        }}>
                                        <KSTrashIcon />
                                    </IconButton>}
                                    message={`Are you sure you want to remove ${row?.original?.documentName}?`}
                                    title={'Delete Document'}
                                    handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                />



                            </Stack>
                        )
                    }
                }
            />
            {customError && <FormHelperText error>{customError}</FormHelperText>}



            <DocumentDialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={docEdit?.length !== 0 ? `Update Task Documents` : `Add Document`}
                body={
                    <SupportingDocumentForm
                        formId={formId}
                        // onSubmit={docsSubmit}
                        editItem={docEdit}
                        hasPartner={formValue?.hasPartner}
                        handleClose={handleClose}
                        documentTypes={documentTypes}
                        setLocalData={setLocalData}
                        docList={docListLibrary}
                    />
                }
            />


        </>
    )
}

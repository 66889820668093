import { ageCalculator } from "../helpers/time-formater";
import { ERROR_MESSAGE } from "./constatnt-variable"
import { MAXLength, ValidationConstant } from "./validation";


export const AssetsCreditValidation = {
    accountType: { required: { value: true, message: ERROR_MESSAGE.selectOne } },
    institutionName: {
        required: { value: true, message: ERROR_MESSAGE.institutionName },
        // minLength: { value: MAXLength.minInsitutionName, message: ERROR_MESSAGE.minInsitutionName },
        maxLength: { value: MAXLength.maxInsitutionName, message: ERROR_MESSAGE.maxInstitutionName }
    },

    accountNo: {
        required: { value: true, message: ERROR_MESSAGE.accountNo },
        // minLength: { value: MAXLength.minaccountNo, message: ERROR_MESSAGE.minaccountNo },
        maxLength: { value: MAXLength.maxaccountNo, message: ERROR_MESSAGE.maxaccountNo }
    },
    marketValue: {
        required: { value: true, message: ERROR_MESSAGE.marketValue },
        minLength: { value: MAXLength.minmarketValue, message: ERROR_MESSAGE.minmarketValue },
        maxLength: { value: MAXLength.maxmarketValue, message: ERROR_MESSAGE.maxmarketValue },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    ownedBy: { required: { value: true, message: ERROR_MESSAGE.selectOne } },
    isDownPayment: { required: { value: true, message: ERROR_MESSAGE.selectOne } },

}

export const CreditOtherValidation = {
    accountType: { required: { value: true, message: ERROR_MESSAGE.selectOne } },
    marketValue: {
        required: { value: true, message: ERROR_MESSAGE.marketValue },
        minLength: { value: MAXLength.minmarketValue, message: ERROR_MESSAGE.minmarketValue },
        maxLength: { value: MAXLength.maxmarketValue, message: ERROR_MESSAGE.maxmarketValue }
    },

}

export const DemographicsValidation = {
    ethinicityStatus: { required: { value: true, message: ERROR_MESSAGE.required } },
    chooseType: { required: { value: true, message: ERROR_MESSAGE.required } },
    text: { required: { value: true, message: ERROR_MESSAGE.required } },
    raceType: { required: { value: true, message: ERROR_MESSAGE.required } },
    raceSubType: { required: { value: true, message: ERROR_MESSAGE.required } },
    tribe: { required: { value: true, message: ERROR_MESSAGE.required } },
    sex: { required: { value: true, message: ERROR_MESSAGE.required } },
}


export const DependentValidation = {
    loanDependentStatus: { required: { value: true, message: ERROR_MESSAGE.required } },
    numberDependents: {
        required: { value: true, message: ERROR_MESSAGE.dependentAge },
        max: { value: 4, message: ERROR_MESSAGE.maxDependentNumber },
        min: { value: 1, message: ERROR_MESSAGE.minDependentNumber }
    },
    ageDependent1: {
        required: { value: true, message: ERROR_MESSAGE.depentNumber },
        min: { value: MAXLength.minDependent, message: ERROR_MESSAGE.minDependent },
        max: { value: MAXLength.maxDependent, message: ERROR_MESSAGE.maxDependent },
    },
    ageDependent2: {
        required: { value: true, message: ERROR_MESSAGE.depentNumber },
        min: { value: MAXLength.minDependent, message: ERROR_MESSAGE.minDependent },
        max: { value: MAXLength.maxDependent, message: ERROR_MESSAGE.maxDependent },
    },

}


export const EmployedValidation = {
    employmentStatus: { required: { value: true, message: ERROR_MESSAGE.required } },
    businessName: {
        required: { value: true, message: ERROR_MESSAGE.businessName },
        // minLength: { value: MAXLength.minbusinessName, message: ERROR_MESSAGE.minbusinessName },
        maxLength: { value: MAXLength.maxbusinessName, message: ERROR_MESSAGE.maxbusinessName },
    },
    employNumber: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    positionOfTitle: {
        required: { value: true, message: ERROR_MESSAGE.positionOfTitle },
        // minLength: { value: MAXLength.minpositionOfTitle, message: ERROR_MESSAGE.minpositionOfTitle },
        maxLength: { value: MAXLength.maxpositionOfTitle, message: ERROR_MESSAGE.maxpositionOfTitle },
    },
    startDate: {
        required: { value: true, message: ERROR_MESSAGE.startDate },
    },
    endDate: {
        required: { value: true, message: ERROR_MESSAGE.endDate },
    },
    stateofCountry: { required: { value: true, message: ERROR_MESSAGE.required } },
    profession: {
        required: { value: true, message: ERROR_MESSAGE.profession },
    },
    payFrequency: {
        required: { value: true, message: ERROR_MESSAGE.payFrequency },
    },
    monthlyIncome: {
        required: { value: true, message: ERROR_MESSAGE.monthlyIncome },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    }

}

export const SecondaryEmployedValidation = {
    ownerShip: { required: { value: true, message: ERROR_MESSAGE.required } },
    secondaryEmploymentType: { required: { value: true, message: ERROR_MESSAGE.required } },
    payFrequency: {
        required: { value: true, message: ERROR_MESSAGE.payFrequency },
    },
    employmentStatus: { required: { value: true, message: ERROR_MESSAGE.required } },
    businessName: {
        required: { value: true, message: ERROR_MESSAGE.businessName },
        // minLength: { value: MAXLength.minbusinessName, message: ERROR_MESSAGE.minbusinessName },
        maxLength: { value: MAXLength.maxbusinessName, message: ERROR_MESSAGE.maxbusinessName },
    },
    employNumber: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    positionOfTitle: {
        required: { value: true, message: ERROR_MESSAGE.positionOfTitle },
        // minLength: { value: MAXLength.minpositionOfTitle, message: ERROR_MESSAGE.minpositionOfTitle },
        maxLength: { value: MAXLength.maxpositionOfTitle, message: ERROR_MESSAGE.maxpositionOfTitle },
    },
    startDate: {
        required: { value: true, message: ERROR_MESSAGE.startDate },
    },
    endDate: {
        required: { value: true, message: ERROR_MESSAGE.endDate },
    },
    stateofCountry: { required: { value: true, message: ERROR_MESSAGE.required } },
    profession: {
        required: { value: true, message: ERROR_MESSAGE.profession },
    },
    monthlyIncome: {
        required: { value: true, message: ERROR_MESSAGE.monthlyIncome },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    }

}

export const SelfEmployedValidation = {

    businessName: {
        required: { value: true, message: ERROR_MESSAGE.businessName },
        minLength: { value: MAXLength.minbusinessName, message: ERROR_MESSAGE.minbusinessName },
        maxLength: { value: MAXLength.maxbusinessName, message: ERROR_MESSAGE.maxbusinessName },
    },
    positionOfTitle: {
        required: { value: true, message: ERROR_MESSAGE.positionOfTitle },
        // minLength: { value: MAXLength.minpositionOfTitle, message: ERROR_MESSAGE.minpositionOfTitle },
        maxLength: { value: MAXLength.maxpositionOfTitle, message: ERROR_MESSAGE.maxpositionOfTitle },
    },
    startDate: {
        required: { value: true, message: ERROR_MESSAGE.startDate },
    },
    profession: {
        required: { value: true, message: ERROR_MESSAGE.profession },
    },

    ownerShip: { required: { value: true, message: ERROR_MESSAGE.required } },
    addressline1: {
        required: { value: true, message: ERROR_MESSAGE.addressLine1 },
        minLength: { value: MAXLength.addressMin, message: ERROR_MESSAGE.addressMinMsg },
        maxLength: { value: MAXLength.addressMax, message: ERROR_MESSAGE.addressMaxMsg }
    },
    addressline2: {
        required: { value: false, message: ERROR_MESSAGE.addressLine2 },
        minLength: { value: MAXLength.addressMin, message: ERROR_MESSAGE.addressMinMsg },
        maxLength: { value: MAXLength.addressMax, message: ERROR_MESSAGE.addressMaxMsg }
    },
    unit: {
        required: { value: false, message: ERROR_MESSAGE.required },
        maxLength: { value: MAXLength.unitMax, message: ERROR_MESSAGE.unitMaxMsg }
    },
    city: {
        required: { value: true, message: ERROR_MESSAGE.city },
        maxLength: { value: MAXLength.cityMax, message: ERROR_MESSAGE.cityMaxMsg }
    },
    country: { required: { value: true, message: ERROR_MESSAGE.country } },
    stateOfCountry: { required: { value: true, message: ERROR_MESSAGE.required } },
    zip: {
        required: { value: true, message: ERROR_MESSAGE.zip },
        minLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip },
        maxLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip }
    },
    averageMonthlyBonus: {
        required: { value: true, message: ERROR_MESSAGE.monthlyBonus },
    },
    averageMonthlyOvertime: {
        required: { value: true, message: ERROR_MESSAGE.monthlyOvertime },
    },
    militaryEntitlement: {
        required: { value: true, message: ERROR_MESSAGE.militaryEntitlement },
    },
    commission: {
        required: { value: true, message: ERROR_MESSAGE.mothlyCommission },
    },
    prevGrossMonthlyIncome: {
        required: { value: true, message: ERROR_MESSAGE.prevGrossMonthlyIncome },
    },
    monthlyIncome: {
        required: { value: true, message: ERROR_MESSAGE.monthlyIncome },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },

}
export const RetiredValidation = {
    retirementDate: {
        required: { value: true, message: ERROR_MESSAGE.retirementDate },
    },
    retirementIncome: {
        required: { value: true, message: ERROR_MESSAGE.retirementIncome },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
};

export const ExtraIncomeValidation = {
    isSideIncomeAvailable: { required: { value: true, message: ERROR_MESSAGE.required } },
    avgMonthlyIncome: { required: { value: false, message: ERROR_MESSAGE.monthlyBonus } },
    validate: (value) => {
        if (!value || isNaN(parseFloat(value))) {
            return ERROR_MESSAGE.required;
        }
        return true;
    },
    avgMonthlyOvertime: { required: { value: false, message: ERROR_MESSAGE.monthlyOvertime } },
    militaryEntitlement: { required: { value: false, message: ERROR_MESSAGE.militaryEntitlement } },
    validate: (value) => {
        if (!value || isNaN(parseFloat(value))) {
            return ERROR_MESSAGE.required;
        }
        return true;
    },
    avgMonthlyComission: { required: { value: false, message: ERROR_MESSAGE.mothlyCommission } },
    validate: (value) => {
        if (!value || isNaN(parseFloat(value))) {
            return ERROR_MESSAGE.required;
        }
        return true;
    }

}


export const MilitaryServiceValidation = {
    statusOfMiliteryService: { required: { value: true, message: ERROR_MESSAGE.required } },
    serviceStatus: { required: { value: true, message: ERROR_MESSAGE.serviceStatus } },
    expireDate: { required: { value: true, message: ERROR_MESSAGE.expireDate } },
}

export const PersonalInfoValidation = {
    firstName: {
        required: { value: true, message: ERROR_MESSAGE.firstName },
        minLength: { value: MAXLength.minFirstName, message: ERROR_MESSAGE.minFirstName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    middleName: {
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    alternateName: {
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    lastName: {
        required: { value: true, message: ERROR_MESSAGE.lastName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    suffix: {
        maxLength: { value: MAXLength.maxNameSuffix, message: ERROR_MESSAGE.maxNameSuffix }
    },
    ssn: {
        required: { value: true, message: ERROR_MESSAGE.ssn },
        minLength: { value: MAXLength.ssn, message: ERROR_MESSAGE.ssnMinMax },
        maxLength: { value: MAXLength.ssn, message: ERROR_MESSAGE.ssnMinMax }
    },


    dateOfBirth: {
        required: { value: true, message: ERROR_MESSAGE.dateOfBirth },
        validate: (value) => {
            if (ageCalculator(value) < 18) {
                return ERROR_MESSAGE.minDateOfBirth;
            }
        }
    },
    statusOfCitizenship: { required: { value: true, message: ERROR_MESSAGE.required } },
    visatype: { required: { value: true, message: ERROR_MESSAGE.required } },
    maritalStatus: { required: { value: true, message: ERROR_MESSAGE.required } }
};

export const ContactValidation = {
    homePhone: {
        required: { value: false, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone },
        validate: (value) => {
            if (!value) {
                return "Phone number cannot be empty";
            }
            if (value.charAt(0) === '0') {
                return "Phone number should not start with 0";
            }
            if (/^(\d)\1+$/.test(value)) {
                return "Phone number should not have all digits the same";
            }
            return true;
        }
    },
    workPhone: {
        required: { value: false, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone },
        validate: (value) => {
            if (value == null) {
                return true;
            }
            if (value.charAt(0) === '0') {
                return "Phone number should not start with 0";
            }
            if (/^(\d)\1+$/.test(value)) {
                return "Phone number should not have all digits the same";
            }
            return true;
        }
    },
    // phone: {
    //     required: { value: true, message: ERROR_MESSAGE.phone },
    //     pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    // },
    // workPhone: {
    //     required: { value: false, message: ERROR_MESSAGE.phone },
    //     pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    // },
    email: {
        required: { value: true, message: ERROR_MESSAGE.email },
        pattern: { value: ValidationConstant.emailRegx, message: ERROR_MESSAGE.validPEmail }
    },
    mailingAddressStatus: { required: { value: true, message: ERROR_MESSAGE.required } },
    mailingAddressline1: {
        required: { value: true, message: ERROR_MESSAGE.addressLine1 },
        minLength: { value: MAXLength.addressMin, message: ERROR_MESSAGE.addressMinMsg },
        maxLength: { value: MAXLength.addressMax, message: ERROR_MESSAGE.addressMaxMsg }
    },
    mailingAddressline2: {
        required: { value: false, message: ERROR_MESSAGE.addressLine2 },
        minLength: { value: MAXLength.addressMin, message: ERROR_MESSAGE.addressMinMsg },
        maxLength: { value: MAXLength.addressMax, message: ERROR_MESSAGE.addressMaxMsg }
    },
    mailingUnit: {
        required: { value: false, message: ERROR_MESSAGE.required },
        maxLength: { value: MAXLength.unitMax, message: ERROR_MESSAGE.unitMaxMsg }
    },
    mailingCity: {
        required: { value: true, message: ERROR_MESSAGE.city },
        maxLength: { value: MAXLength.cityMax, message: ERROR_MESSAGE.cityMaxMsg }
    },
    mCountry: { required: { value: true, message: ERROR_MESSAGE.country } },
    mailingStateofCountry: { required: { value: true, message: ERROR_MESSAGE.required } },
    mailingCountry: { required: { value: true, message: ERROR_MESSAGE.required } },
    mailingZip: {
        required: { value: true, message: ERROR_MESSAGE.zip },
        minLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip },
        maxLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip }
    },
    ownerShipType: { required: { value: true, message: ERROR_MESSAGE.required } },
    year: {
        required: { value: true, message: ERROR_MESSAGE.year },
        minLength: { value: MAXLength.minstayPeriod, message: ERROR_MESSAGE.minPeriodStay },
        maxLength: { value: MAXLength.maxstayPeriod, message: ERROR_MESSAGE.maxPeriodStay },
    },

    month: {
        required: { value: true, message: ERROR_MESSAGE.month },
        min: { value: MAXLength.minPeriodMonth, message: ERROR_MESSAGE.minPeriodMonth },
        max: { value: MAXLength.maxPeriodMonth, message: ERROR_MESSAGE.minPeriodMonth },
    },

    rentAmount: {
        required: { value: true, message: ERROR_MESSAGE.rentAmount },
        minLength: { value: MAXLength.minRentAmount, message: ERROR_MESSAGE.minRentAmount },
        maxLength: { value: MAXLength.maxRentAmount, message: ERROR_MESSAGE.maxRentAmount },
    }


}

export const ContactAdditionalValidation = {

    year: {
        required: { value: true, message: ERROR_MESSAGE.year },
        minLength: { value: MAXLength.minstayPeriod, message: ERROR_MESSAGE.minPeriodStay },
        maxLength: { value: MAXLength.maxstayPeriod, message: ERROR_MESSAGE.maxPeriodStay },
    },

    month: {
        required: { value: true, message: ERROR_MESSAGE.month },
        minLength: { value: MAXLength.minstayPeriod, message: ERROR_MESSAGE.minPeriodStayMonth },
        maxLength: { value: MAXLength.maxstayPeriod, message: ERROR_MESSAGE.maxPeriodStayMonth },
    },
    ownerShipType: { required: { value: true, message: ERROR_MESSAGE.required } },
    rentAmount: {
        required: { value: true, message: ERROR_MESSAGE.required },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }

    },


}

export const AddionalIncomeValidation = {
    incomeSource: { required: { value: true, message: ERROR_MESSAGE.required } },
    monthIncome: { required: { value: true, message: ERROR_MESSAGE.required } },


}

export const PropertyAddressValidation = {
    addressline1: {
        required: { value: true, message: ERROR_MESSAGE.addressLine1 },
        minLength: { value: MAXLength.addressMin, message: ERROR_MESSAGE.addressMinMsg },
        maxLength: { value: MAXLength.addressMax, message: ERROR_MESSAGE.addressMaxMsg }
    },
    addressline2: {
        required: { value: false, message: ERROR_MESSAGE.addressLine2 },
        minLength: { value: MAXLength.addressMin, message: ERROR_MESSAGE.addressMinMsg },
        maxLength: { value: MAXLength.addressMax, message: ERROR_MESSAGE.addressMaxMsg }
    },
    unit: {
        required: { value: false, message: ERROR_MESSAGE.required },
        maxLength: { value: MAXLength.unitMax, message: ERROR_MESSAGE.unitMaxMsg }
    },
    city: {
        required: { value: true, message: ERROR_MESSAGE.city },
        maxLength: { value: MAXLength.cityMax, message: ERROR_MESSAGE.cityMaxMsg }
    },
    county: { required: { value: true, message: ERROR_MESSAGE.county } },
    country: { required: { value: true, message: ERROR_MESSAGE.country } },
    stateofCountry: { required: { value: true, message: ERROR_MESSAGE.required } },
    zip: {
        required: { value: true, message: ERROR_MESSAGE.zip },
        minLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip },
        maxLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip }
    },
}

export const AdditionalDocumentsValidation = {
    documentName: { required: { value: true, message: ERROR_MESSAGE.documentName } },
    documentType: { required: { value: true, message: ERROR_MESSAGE.required } },
    note: { required: { value: true, message: ERROR_MESSAGE.note } },
    docId: { required: { value: true, message: ERROR_MESSAGE.required } },

};

export const GuidedDocuments = {
    checkbox: { required: { value: true, message: ERROR_MESSAGE.required } },
};

export const GettingStartedCoBorrowerValidation = {
    loanCoBorrowerStatus: { required: { value: true, message: ERROR_MESSAGE.required } },
    firstName: {
        required: { value: true, message: ERROR_MESSAGE.firstName },
        minLength: { value: MAXLength.minFirstName, message: ERROR_MESSAGE.minFirstName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    lastName: {
        required: { value: true, message: ERROR_MESSAGE.lastName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    phone: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    email: {
        required: { value: true, message: ERROR_MESSAGE.email },
        pattern: { value: ValidationConstant.emailRegx, message: ERROR_MESSAGE.validPEmail }
    },
}

export const GettingStartedValidation = {
    loanPartnerStatus: { required: { value: true, message: ERROR_MESSAGE.required } },
    firstName: {
        required: { value: true, message: ERROR_MESSAGE.firstName },
        minLength: { value: MAXLength.minFirstName, message: ERROR_MESSAGE.minFirstName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    lastName: {
        required: { value: true, message: ERROR_MESSAGE.lastName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    phone: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    email: {
        required: { value: true, message: ERROR_MESSAGE.email },
        pattern: { value: ValidationConstant.emailRegx, message: ERROR_MESSAGE.validPEmail }
    },
}

export const LiabilitiesValidation = {
    creditType: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },
    companyName: {
        required: { value: true, message: ERROR_MESSAGE.companyName },
        maxLength: { value: MAXLength.maxCompanyName, message: ERROR_MESSAGE.maxCompanyName }
    },
    propertyAddress: {
        required: { value: true, message: ERROR_MESSAGE.propertyAddress },
    },
    accountNo: {
        required: { value: true, message: ERROR_MESSAGE.accountNo },
        minLength: { value: MAXLength.minaccountNo, message: ERROR_MESSAGE.minaccountNo },
        maxLength: { value: MAXLength.maxaccountNo, message: ERROR_MESSAGE.maxaccountNo }
    },
    unpaidBalance: {
        required: { value: true, message: ERROR_MESSAGE.unpaidBalance },
        maxLength: { value: MAXLength.maxUnpaidBalance, message: ERROR_MESSAGE.maxUnpaidBalance },
        validate: (value) => {
            if (isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    monthlyPay: {
        required: { value: true, message: ERROR_MESSAGE.monthlyPay },
        maxLength: { value: MAXLength.maxMonthlyPay, message: ERROR_MESSAGE.maxMonthlyPay },
        validate: (value) => {
            if (isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    monthsLeft: {
        required: { value: false, message: ERROR_MESSAGE.monthsLeft },
        maxLength: { value: MAXLength.maxMonthsLeft, message: ERROR_MESSAGE.maxMonthsLeft }
    },
    liabilityType: {
        required: { value: true, message: ERROR_MESSAGE.liabilityType }
    },
    /* ownedBy: {
         required: { value: true, message: ERROR_MESSAGE.ownedBy }
     },*/
    omitType: {
        required: { value: true, message: ERROR_MESSAGE.omitType },
    },
    liabilityType: {
        required: { value: true, message: ERROR_MESSAGE.propertyTypes },
    }

}
export const LoanDeclarationValidation = {
    ownershipProperty: { required: { value: true, message: ERROR_MESSAGE.ownershipProperty, }, },
    primaryResident: { required: { value: true, message: ERROR_MESSAGE.primaryResident, }, },
    declarationTypeOfProperty: { required: { value: true, message: ERROR_MESSAGE.declarationTypeOfProperty, }, },
    declarationTitleOfProperty: { required: { value: true, message: ERROR_MESSAGE.declarationTitleOfProperty, }, },
    affiliation: { required: { value: true, message: ERROR_MESSAGE.affiliation, }, },
    moneyFromAnotherParty: { required: { value: true, message: ERROR_MESSAGE.moneyFromAnotherParty, }, },
    amountFromAnotherParty: { required: { value: true, message: ERROR_MESSAGE.amountFromAnotherParty, }, },
    validate: (value) => {
        if (!value || isNaN(parseFloat(value))) {
            return ERROR_MESSAGE.required;
        }
        return true;
    },
    loanOnAnotherProperty: { required: { value: true, message: ERROR_MESSAGE.loanOnAnotherProperty, }, },
    creditOnBeforeLoan: { required: { value: true, message: ERROR_MESSAGE.creditOnBeforeLoan, }, },
    lien: { required: { value: true, message: ERROR_MESSAGE.lien, }, },
    coSignerOrGuarantor: { required: { value: true, message: ERROR_MESSAGE.coSignerOrGuarantor, }, },
    outstandingJudgement: { required: { value: true, message: ERROR_MESSAGE.outstandingJudgement, }, },
    federalDept: { required: { value: true, message: ERROR_MESSAGE.federalDept, }, },
    partyLawsuit: { required: { value: true, message: ERROR_MESSAGE.partyLawsuit, }, },
    convertTitle: { required: { value: true, message: ERROR_MESSAGE.convertTitle, }, },
    preForeclosure: { required: { value: true, message: ERROR_MESSAGE.preForeclosure, }, },
    propertyForeclosed: { required: { value: true, message: ERROR_MESSAGE.propertyForeclosed, }, },
    declaredBankruptcy: { required: { value: true, message: ERROR_MESSAGE.declaredBankruptcy, }, },
    typesOfBankruptcy: { required: { value: true, message: ERROR_MESSAGE.typesOfBankruptcy, }, },
}


export const LoanDetailsValidation = {
    buyingOrRefinancing: {
        required: {
            value: true,
            message: ERROR_MESSAGE.buyingOrRefinancing,
        },
    },

    loanCategory: {
        required: {
            value: true,
            message: ERROR_MESSAGE.loanCategory,
        },
    },


    loanTerm: {
        required: {
            value: true,
            message: ERROR_MESSAGE.loanTerm,
        },


    },

    statusOfPurchase: {
        required: {
            value: true,
            message: ERROR_MESSAGE.statusOfPurchase,
        },
    },
    statusOfRealtor: {
        required: {
            value: true,
            message: ERROR_MESSAGE.statusOfRealtor,
        },
    },
    name: {
        required: { value: true, message: ERROR_MESSAGE.name },
    },
    companyName: {
        required: {
            value: true,
            message: ERROR_MESSAGE.companyName,
        },
    },
    phone: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: {
            value: ValidationConstant.usPhoneNumber,
            message: "Enter valid Phone no",
        },
    },
    email: {
        required: { value: true, message: ERROR_MESSAGE.email },
        pattern: {
            value: ValidationConstant.emailRegx,
            message: "Enter valid email Id",
        },
    },
    solarPurchasedStatus: {
        required: {
            value: true,
            message: ERROR_MESSAGE.solarPurchasedStatus,
        },
    },
    isRecentlyRefinanced: {
        required: {
            value: true,
            message: ERROR_MESSAGE.isRecentlyRefinanced,
        },
    },


    mixedUsePropertyStatus: {
        required: {
            value: true,
            message: ERROR_MESSAGE.mixedUsePropertyStatus,
        },
    },

    manufacturedHomeStatus: {
        required: {
            value: true,
            message: ERROR_MESSAGE.manufacturedHomeStatus,
        },
    },



    isMortgageStatementAvailable: {
        required: {
            value: true,
            message: ERROR_MESSAGE.isMortgageStatementAvailable,
        },
    },
    typeOfRefinance: {
        required: {
            value: true,
            message: ERROR_MESSAGE.typeOfRefinance,
        },
    },
    reasonOfRefinance: {
        required: {
            value: true,
            message: ERROR_MESSAGE.reasonOfRefinance,
        },
    },
    cashoutAmount: {
        required: {
            value: true,
            message: ERROR_MESSAGE.cashoutAmount,
        },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    loanTermType: {
        required: {
            value: true,
            message: ERROR_MESSAGE.loanTermType,
        },
    },
    loanOptions: {
        required: {
            value: true,
            message: ERROR_MESSAGE.loanOptions,
        },
    },
    homeHOAManagement: {
        required: {
            value: true,
            message: ERROR_MESSAGE.homeHOAManagement,
        },
    },
    propertyType: {
        required: {
            value: true,
            message: ERROR_MESSAGE.propertyType,
        },
    },
    attachmentType: {
        required: {
            value: true,
            message: ERROR_MESSAGE.attachmentType,
        },
    },
    occupancyType: {
        required: {
            value: true,
            message: ERROR_MESSAGE.occupancyType,
        },
    },
    addressLine1: {
        required: {
            value: true,
            message: ERROR_MESSAGE.addressLine1,
        },
        minLength: { value: MAXLength.addressMin, message: ERROR_MESSAGE.addressMinMsg },
        maxLength: { value: MAXLength.addressMax, message: ERROR_MESSAGE.addressMaxMsg }
    },
    addressLine2: {
        required: {
            value: false,
            message: ERROR_MESSAGE.addressLine2,
        },
        minLength: { value: MAXLength.addressMin, message: ERROR_MESSAGE.addressMinMsg },
        maxLength: { value: MAXLength.addressMax, message: ERROR_MESSAGE.addressMaxMsg }
    },
    unit: {
        required: { value: false, message: ERROR_MESSAGE.unit },
    },
    city: {
        required: { value: true, message: ERROR_MESSAGE.city },
    },
    county: {
        required: { value: true, message: ERROR_MESSAGE.county },
    },
    country: {
        required: { value: true, message: ERROR_MESSAGE.country },
    },
    state: {
        required: { value: true, message: ERROR_MESSAGE.state },
    },
    zip: {
        required: { value: true, message: ERROR_MESSAGE.zip },
        minLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip },
        maxLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip },
    },
    closingDate: {
        required: {
            value: true,
            message: ERROR_MESSAGE.closingDate,
        },
    },
    datePropertyAcquired: {
        required: {
            value: true,
            message: ERROR_MESSAGE.datePropertyAcquired,
        },
    },
    lastFinancedDate: {
        required: {
            value: true,
            message: ERROR_MESSAGE.lastFinancedDate,
        },
    },
    propertyBuiltDate: {
        required: {
            value: true,
            message: ERROR_MESSAGE.propertyBuiltDate,
        },
    },
    purchasePrice: {
        required: {
            value: true,
            message: ERROR_MESSAGE.purchasePrice,
        },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    estimatedPropertyValue: {
        required: {
            value: true,
            message: ERROR_MESSAGE.estimatedPropertyValue,
        },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }

    },
    downPayment: {
        required: {
            value: true,
            message: ERROR_MESSAGE.downPayment,
        },
        min: {
            value: 0,
            message: ERROR_MESSAGE.downPayment,
        },
        max: {
            value: 100,
            message: ERROR_MESSAGE.downPayment,
        },
    },


    rateMortgagePayment: {
        required: {
            value: true,
            message: ERROR_MESSAGE.rateMortgagePayment,
        },
        min: {
            value: 0,
            message: ERROR_MESSAGE.rateMortgagePayment,
        },
        max: {
            value: 100,
            message: ERROR_MESSAGE.rateMortgagePayment,
        },
    },








    HOIAmount: {
        required: {
            value: true,
            message: ERROR_MESSAGE.HOIAmount,
        },
    },
    HOADues: {
        required: {
            value: true,
            message: ERROR_MESSAGE.HOADues,
        },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    investmentMonthIncome: {
        required: {
            value: true,
            message: ERROR_MESSAGE.investmentMonthIncome,
        },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    downPaymentAmount: {
        required: { value: true, message: ERROR_MESSAGE.required },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    loanAmount: {
        required: { value: true, message: ERROR_MESSAGE.loanAmount },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    typeOfTitle: {
        required: {
            value: true,
            message: ERROR_MESSAGE.typeOfTitle,
        },
    },
    solarleaseapyment: {
        required: {
            value: true,
            message: ERROR_MESSAGE.solarleaseapyment,
        },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },


    propertyInsurance: {
        required: {
            value: true,
            message: ERROR_MESSAGE.propertyInsurance,
        },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },


    countryPropertyTax: {
        required: {
            value: true,
            message: ERROR_MESSAGE.countryPropertyTax,
        },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    mortgageInsurance: {
        required: {
            value: false,
            message: ERROR_MESSAGE.mortgageInsurance,
        },

    },
};

export const RealEstateValidation = {
    realestateStatus: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    solarStatus: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    isHoaManaged: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    newLeaderStatus: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    newLeaderStatement: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    propertyType: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    marketValue: {
        required: { value: true, message: ERROR_MESSAGE.marketValue },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    realEstateOwnedBy: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    propertyStatus: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    propertyMortgage: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    intendedOccupancy: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    currentOccupancy: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    propertyInsurance: {
        required: { value: true, message: ERROR_MESSAGE.propertyInsurance },
        minLength: { value: MAXLength.propertyMin, message: ERROR_MESSAGE.minpropertyInsurance },
        maxLength: { value: MAXLength.propertyMax, message: ERROR_MESSAGE.maxpropertyInsurance },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    propertyTaxes: {
        required: { value: true, message: ERROR_MESSAGE.propertyTaxes },
        minLength: { value: MAXLength.propertyMin, message: ERROR_MESSAGE.minpropertyTaxes },
        maxLength: { value: MAXLength.propertyMax, message: ERROR_MESSAGE.maxpropertyTaxes },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    solarLeasePayment: {
        required: { value: true, message: ERROR_MESSAGE.solarLeasePayment },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    hoaPayment: {
        required: { value: true, message: ERROR_MESSAGE.hoaPayment },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }

    },
    monthlyIncome: {
        required: { value: false, message: ERROR_MESSAGE.monthlyIncome },

    },
    mortgageInsurance: {
        required: { value: true, message: ERROR_MESSAGE.mortgageInsurance },
        minLength: { value: MAXLength.propertyMin, message: ERROR_MESSAGE.minMortgageInsurance },
        maxLength: { value: MAXLength.propertyMax, message: ERROR_MESSAGE.maxMortgageInsurance },

    }

}


export const AdditionalIncomeValidation = {
    incomeSource: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    monthIncome: {
        required: { value: true, message: ERROR_MESSAGE.required },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    }
}

export const GiftAndGrantsValidation = {
    status: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    assetType: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    settlementType: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    sourceType: {
        required: { value: true, message: ERROR_MESSAGE.required }
    },
    marketValue: {

        required: { value: true, message: ERROR_MESSAGE.required },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }

    },
}



// Form Validation Start
export const loanSummaryStatisticsValidation = {
    loanCode: {
        required: {
            value: true,
            message: ERROR_MESSAGE.loanCode,
        },
    },
    loanAmount: {
        required: {
            value: true,
            message: ERROR_MESSAGE.loanAmount,
        },
    },
    loanTerm: {
        required: {
            value: true,
            message: ERROR_MESSAGE.loanTerm,
        },
    },
    interestRate: {
        required: {
            value: true,
            message: ERROR_MESSAGE.interestRate,
        },
    },
    empAmount: {
        required: {
            value: true,
            message: ERROR_MESSAGE.empAmount,
        },
    },
    emeAmount: {
        required: {
            value: true,
            message: ERROR_MESSAGE.emeAmount,
        },
    },
    lockStatus: {
        required: {
            value: true,
            message: ERROR_MESSAGE.lockStatus,
        },
    },
    lockExpirationDate: {
        required: {
            value: true,
            message: ERROR_MESSAGE.lockExpirationDate,
        },
    },
    loanType: {
        required: {
            value: true,
            message: ERROR_MESSAGE.loanType,
        },
    },
    appraisalStatus: {
        required: {
            value: true,
            message: ERROR_MESSAGE.appraisalStatus,
        },
    },
};
// Form Validation End

export const loanStateValidation = {
    loanState: {
        required: { value: true, message: ERROR_MESSAGE.loanState },
    },
};

export const MLO_MLP_CHANGE_VALIDATION = {
    changeMLOMLP: {
        required: {
            value: true,
            message: ERROR_MESSAGE.changeMLOMLP,
        },
    },
};

export const TaskValidation = {
    taskType: {
        required: { value: true, message: ERROR_MESSAGE.taskType },
    },
    title: {
        required: { value: true, message: ERROR_MESSAGE.title },
    },
    description: {
        required: { value: true, message: ERROR_MESSAGE.description },
    },
    isLender: {
        required: { value: true, message: ERROR_MESSAGE.isLender },
    },
    dueDate: {
        required: { value: true, message: ERROR_MESSAGE.dueDate },
    },
    brokerDocId: {
        required: { value: true, message: ERROR_MESSAGE.brokerDocId },
    },
    priority: {
        required: { value: true, message: ERROR_MESSAGE.priority },
    },
};

export const ResloveBorrowerValidation = {
    borrowerNote: {
        required: { value: true, message: ERROR_MESSAGE.borrowerNote },
    },
    borrowerDocId: {
        required: { value: true, message: ERROR_MESSAGE.borrowerDocId },
    },
};

export const ResloveBrokerRules = {
    ownerNote: {
        required: { value: true, message: ERROR_MESSAGE.ownerNote },
    },
};

export const CreditReportValidation = {
    coBorrower: { required: { value: true, message: ERROR_MESSAGE.coBorrower } },
    primaryBorrower: { required: { value: true, message: ERROR_MESSAGE.required } }
}

export const PreApprovalFormValidation = {
    firstName: {
        required: { value: true, message: ERROR_MESSAGE.firstName },
        minLength: { value: MAXLength.minFirstName, message: ERROR_MESSAGE.minFirstName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    lastName: {
        required: { value: true, message: ERROR_MESSAGE.lastName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    email: {
        required: { value: true, message: ERROR_MESSAGE.email },
        pattern: { value: ValidationConstant.emailRegx, message: ERROR_MESSAGE.validPEmail }
    },
    dateOfBirth: {
        required: { value: true, message: ERROR_MESSAGE.dateOfBirth },
        validate: (value) => {
            if (ageCalculator(value) < 18) {
                return ERROR_MESSAGE.minDateOfBirth;
            }
        }
    },
    phone: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    ssn: {
        required: { value: true, message: ERROR_MESSAGE.ssn },
        minLength: { value: MAXLength.ssn, message: ERROR_MESSAGE.ssnMinMax },
        maxLength: { value: MAXLength.ssn, message: ERROR_MESSAGE.ssnMinMax }
    },
    monthlyIncome: {
        required: { value: true, message: ERROR_MESSAGE.monthlyIncome },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    totalLiabilities: {
        required: { value: true, message: ERROR_MESSAGE.required },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    averageScore: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },
    loanType: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },
    loanPurpose: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },
    propertyZip: {
        required: { value: true, message: ERROR_MESSAGE.zip },
        minLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip },
        maxLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip }
    },
    propertyAddress: {
        required: { value: true, message: ERROR_MESSAGE.propertyAddress },
    },
    salePrice: {
        required: { value: true, message: ERROR_MESSAGE.salePrice },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    issueDate: {
        required: { value: true, message: ERROR_MESSAGE.issueDate },
    },
    interestRate: {
        required: { value: true, message: ERROR_MESSAGE.interestRate },
    },
    loanTerm: {
        required: { value: true, message: ERROR_MESSAGE.loanTerm },
    },
    maxPurchasePriceEligibility: {
        required: { value: true, message: ERROR_MESSAGE.maxPurchasePriceEligibility },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    loanToValue: {
        required: { value: true, message: ERROR_MESSAGE.loanToValue },
    },
    loanAmount: {
        required: { value: true, message: ERROR_MESSAGE.loanAmount },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
    mmpAmountPerMonth: {
        required: { value: true, message: ERROR_MESSAGE.mmpAmountPerMonth },
        validate: (value) => {
            if (!value || isNaN(parseFloat(value))) {
                return ERROR_MESSAGE.required;
            }
            return true;
        }
    },
}

export const QuoteFormValidation = {
    firstName: {
        required: { value: true, message: ERROR_MESSAGE.firstName },
        minLength: { value: MAXLength.minFirstName, message: ERROR_MESSAGE.minFirstName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    lastName: {
        required: { value: true, message: ERROR_MESSAGE.lastName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    email: {
        required: { value: true, message: ERROR_MESSAGE.email },
        pattern: { value: ValidationConstant.emailRegx, message: ERROR_MESSAGE.validPEmail }
    },
    dateOfBirth: {
        required: { value: true, message: ERROR_MESSAGE.dateOfBirth },
        validate: (value) => {
            if (ageCalculator(value) < 18) {
                return ERROR_MESSAGE.minDateOfBirth;
            }
        }
    },
    phone: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    ssn: {
        required: { value: true, message: ERROR_MESSAGE.ssn },
        minLength: { value: MAXLength.ssn, message: ERROR_MESSAGE.ssnMinMax },
        maxLength: { value: MAXLength.ssn, message: ERROR_MESSAGE.ssnMinMax }
    },
    monthlyIncome: {
        required: { value: true, message: ERROR_MESSAGE.monthlyIncome },
    },
    totalLiabilities: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },
    // loanType: {
    //   required: { value: true, message: ERROR_MESSAGE.required },
    // },
    // loanPurpose: {
    //   required: { value: true, message: ERROR_MESSAGE.required },
    // },
    // propertyZip: {
    //   required: { value: true, message: ERROR_MESSAGE.zip },
    //   minLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip },
    //   maxLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip }
    // },
    // propertyAddress: {
    //   required: { value: true, message: ERROR_MESSAGE.propertyAddress },
    // },
    // salePrice: {
    //   required: { value: true, message: ERROR_MESSAGE.salePrice },
    // },
    // issueDate: {
    //   required: { value: true, message: ERROR_MESSAGE.issueDate },
    // },
    // loanTerm: {
    //   required: { value: true, message: ERROR_MESSAGE.loanTerm },
    // },
    interestRate: {
        required: { value: true, message: ERROR_MESSAGE.interestRate },
    },
    points: {
        required: { value: true, message: ERROR_MESSAGE.points },
    },
    pmiAmountPerMonth: {
        required: { value: true, message: ERROR_MESSAGE.pmiAmountPerMonth },
    },
    mmpAmountPerMonth: {
        required: { value: true, message: ERROR_MESSAGE.mmpAmountPerMonth },
    },

    sheetFirstName: {
        required: { value: true, message: ERROR_MESSAGE.firstName },
        minLength: { value: MAXLength.minFirstName, message: ERROR_MESSAGE.minFirstName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    sheetLastName: {
        required: { value: true, message: ERROR_MESSAGE.lastName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    sheetEmail: {
        required: { value: true, message: ERROR_MESSAGE.email },
        pattern: { value: ValidationConstant.emailRegx, message: ERROR_MESSAGE.validPEmail }
    },
    sheetNMLS: {
        required: { value: true, message: ERROR_MESSAGE.sheetNMLS },
        minLength: { value: MAXLength.nmlsIdMin, message: ERROR_MESSAGE.sheetNMLSValid },
        maxLength: { value: MAXLength.nmlsIdMax, message: ERROR_MESSAGE.sheetNMLSValid }
    },
    sheetPhone: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    sheetPhoneAlt: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    sheetLoanType: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },
    sheetLoanPurpose: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },
    sheetZip: {
        required: { value: true, message: ERROR_MESSAGE.zip },
        minLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip },
        maxLength: { value: MAXLength.zip, message: ERROR_MESSAGE.validZip }
    },
    sheetPropertyAddress: {
        required: { value: true, message: ERROR_MESSAGE.propertyAddress },
    },
    sheetSalePrice: {
        required: { value: true, message: ERROR_MESSAGE.salePrice },
    },
    sheetIssueDate: {
        required: { value: true, message: ERROR_MESSAGE.issueDate },
    },
    sheetLoanAmount: {
        required: { value: true, message: ERROR_MESSAGE.sheetLoanAmount },
    },
    sheetLoanTerm: {
        required: { value: true, message: ERROR_MESSAGE.sheetLoanTerm },
    },
    sheetInterestRate: {
        required: { value: true, message: ERROR_MESSAGE.sheetInterestRate },
    },
    sheetAnnualPercentageRate: {
        required: { value: true, message: ERROR_MESSAGE.sheetAnnualPercentageRate },
    },
    sheetLoanToValue: {
        required: { value: true, message: ERROR_MESSAGE.sheetLoanToValue },
    },
    sheetPrincipalAndInterest: {
        required: { value: true, message: ERROR_MESSAGE.sheetPrincipalAndInterest },
    },
    sheetOtherFinancing: {
        required: { value: true, message: ERROR_MESSAGE.sheetOtherFinancing },
    },
    sheetOthers: {
        required: { value: true, message: ERROR_MESSAGE.sheetOthers },
    },
    sheetHazardInsurance: {
        required: { value: true, message: ERROR_MESSAGE.sheetHazardInsurance },
    },
    sheetPropertyTaxes: {
        required: { value: true, message: ERROR_MESSAGE.sheetPropertyTaxes },
    },
    sheetMortgageInsurance: {
        required: { value: true, message: ERROR_MESSAGE.sheetMortgageInsurance },
    },
    sheetHOADues: {
        required: { value: true, message: ERROR_MESSAGE.sheetHOADues },
    },

    sheetFundDownPayment: {
        required: { value: true, message: ERROR_MESSAGE.sheetFundDownPayment },
    },
    sheetFundClosingCosts: {
        required: { value: true, message: ERROR_MESSAGE.sheetFundClosingCosts },
    },
    sheetFundReserves: {
        required: { value: true, message: ERROR_MESSAGE.sheetFundReserves },
    },
    sheetFundFundingFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetFundFundingFee },
    },
    sheetCreditsEarnestMoney: {
        required: { value: true, message: ERROR_MESSAGE.sheetCreditsEarnestMoney },
    },
    sheetCreditsSellerCredit: {
        required: { value: true, message: ERROR_MESSAGE.sheetCreditsSellerCredit },
    },
    sheetCreditsLenderCredit: {
        required: { value: true, message: ERROR_MESSAGE.sheetCreditsLenderCredit },
    },
    sheetCreditsSubordinateFinancing: {
        required: { value: true, message: ERROR_MESSAGE.sheetCreditsSubordinateFinancing },
    },

    sheetOriginationAdministrationFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetOriginationAdministrationFee },
    },
    sheetOriginationDiscountPoints: {
        required: { value: true, message: ERROR_MESSAGE.sheetOriginationDiscountPoints },
    },

    sheetLenderAppraisalFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetLenderAppraisalFee },
    },
    sheetLenderCreditReportFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetLenderCreditReportFee },
    },
    sheetLenderFloodCertificationFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetLenderFloodCertificationFee },
    },
    sheetLenderMERSFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetLenderMERSFee },
    },
    sheetLenderNewDebtMonitoring: {
        required: { value: true, message: ERROR_MESSAGE.sheetLenderNewDebtMonitoring },
    },
    sheetLenderTaxServiceFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetLenderTaxServiceFee },
    },

    sheetTitleCountyTransferTax: {
        required: { value: true, message: ERROR_MESSAGE.sheetTitleCountyTransferTax },
    },
    sheetTitleRecordingFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetTitleRecordingFee },
    },
    sheetTitleInsurance: {
        required: { value: true, message: ERROR_MESSAGE.sheetTitleInsurance },
    },
    sheetTitleCourierFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetTitleCourierFee },
    },
    sheetTitleMobileNotaryFee: {
        required: { value: true, message: ERROR_MESSAGE.sheetTitleMobileNotaryFee },
    },
    sheetTitleSettlement: {
        required: { value: true, message: ERROR_MESSAGE.sheetTitleSettlement },
    },

    sheetPrePaidItemsPerDiemInterest: {
        required: { value: true, message: ERROR_MESSAGE.sheetPrePaidItemsPerDiemInterest },
    },
    sheetPrePaidItemsEstimatedReserves: {
        required: { value: true, message: ERROR_MESSAGE.sheetPrePaidItemsEstimatedReserves },
    },

    sheetTransactionSalePrice: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionSalePrice },
    },
    sheetTransactionReserves: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionReserves },
    },
    sheetTransactionIssueDate: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionIssueDate },
    },
    sheetTransactionLoanAmount: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionLoanAmount },
    },
    sheetTransactionLoanTerm: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionLoanTerm },
    },
    sheetTransactionInterestRate: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionInterestRate },
    },
    sheetTransactionAnnualPercentageRate: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionAnnualPercentageRate },
    },
    sheetTransactionLoanToValue: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionLoanToValue },
    },
    sheetTransactionPrincipalAndInterest: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionPrincipalAndInterest },
    },
    sheetTransactionOtherFinancing: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionOtherFinancing },
    },
    sheetTransactionOthers: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionOthers },
    },
    sheetTransactionHazardInsurance: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionHazardInsurance },
    },
    sheetTransactionPropertyTaxes: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionPropertyTaxes },
    },
    sheetTransactionMortgageInsurance: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionMortgageInsurance },
    },
    sheetTransactionHOADues: {
        required: { value: true, message: ERROR_MESSAGE.sheetTransactionHOADues },
    },

}

export const AdditionalLoanContactValidation = {
    name: {
        required: { value: true, message: ERROR_MESSAGE.name },
    },
    phone: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    email: {
        required: { value: true, message: ERROR_MESSAGE.email },
        pattern: { value: ValidationConstant.emailRegx, message: ERROR_MESSAGE.validPEmail }
    },
    companyName: {
        required: { value: true, message: ERROR_MESSAGE.companyName },
    },
    designator: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },

}

export const LoanNoteValidation = {
    note: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },

}

export const TeamInvitationValidation = {
    role: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },
    firstName: {
        required: { value: true, message: ERROR_MESSAGE.firstName },
        minLength: { value: MAXLength.minFirstName, message: ERROR_MESSAGE.minFirstName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    lastName: {
        required: { value: true, message: ERROR_MESSAGE.lastName },
        maxLength: { value: MAXLength.maxName, message: ERROR_MESSAGE.maxName }
    },
    email: {
        required: { value: true, message: ERROR_MESSAGE.email },
        pattern: { value: ValidationConstant.emailRegx, message: ERROR_MESSAGE.validPEmail }
    },
    phone: {
        required: { value: true, message: ERROR_MESSAGE.phone },
        pattern: { value: ValidationConstant.usPhoneNumber, message: ERROR_MESSAGE.validPhone }
    },
    invitationMessage: {
        required: { value: true, message: ERROR_MESSAGE.required },
    },
}

export const LoanDocumentValidation = {

    documentType: {
        required: { value: false, message: ERROR_MESSAGE.documentType },
        // minLength: { value: MAXLength.minInsitutionName, message: ERROR_MESSAGE.minInsitutionName },
        // maxLength: { value: MAXLength.maxInsitutionName, message: ERROR_MESSAGE.maxInstitutionName }
    },
    customDocumentType: {
        required: { value: false, message: ERROR_MESSAGE.customDocumentType },
        // minLength: { value: MAXLength.minInsitutionName, message: ERROR_MESSAGE.minInsitutionName },
        // maxLength: { value: MAXLength.maxInsitutionName, message: ERROR_MESSAGE.maxInstitutionName }
    },

    visibility: {
        required: { value: true, message: "Required visibility" },
    },
    borrowerOnlyDocument:
        { required: { value: true, message: ERROR_MESSAGE.required } },
}


export const TaskTypeValidation = {

    taskType: {
        required: { value: true, message: ERROR_MESSAGE.taskType },
        // minLength: { value: MAXLength.minInsitutionName, message: ERROR_MESSAGE.minInsitutionName },
        // maxLength: { value: MAXLength.maxInsitutionName, message: ERROR_MESSAGE.maxInstitutionName }
    },
    documentType: {
        required: { value: true, message: ERROR_MESSAGE.documentType },
        // minLength: { value: MAXLength.minInsitutionName, message: ERROR_MESSAGE.minInsitutionName },
        // maxLength: { value: MAXLength.maxInsitutionName, message: ERROR_MESSAGE.maxInstitutionName }
    },
    customDocumentType: {
        required: { value: true, message: ERROR_MESSAGE.documentType },
        // minLength: { value: MAXLength.minInsitutionName, message: ERROR_MESSAGE.minInsitutionName },
        // maxLength: { value: MAXLength.maxInsitutionName, message: ERROR_MESSAGE.maxInstitutionName }
    },

    documentDescriptor: {
        required: { value: true, message: ERROR_MESSAGE.documentDescriptor },
        // minLength: { value: MAXLength.minInsitutionName, message: ERROR_MESSAGE.minInsitutionName },
        // maxLength: { value: MAXLength.maxInsitutionName, message: ERROR_MESSAGE.maxInstitutionName }
    },

    documentNote: {
        required: { value: true, message: ERROR_MESSAGE.note },
        // minLength: { value: MAXLength.minInsitutionName, message: ERROR_MESSAGE.minInsitutionName },
        // maxLength: { value: MAXLength.maxInsitutionName, message: ERROR_MESSAGE.maxInstitutionName }
    },

    taskDescription: {
        required: { value: true, message: ERROR_MESSAGE.description },
        // minLength: { value: MAXLength.minaccountNo, message: ERROR_MESSAGE.minaccountNo },
        // maxLength: { value: MAXLength.maxaccountNo, message: ERROR_MESSAGE.maxaccountNo }
    },


    taskDescription: {
        required: "Task description is required",
        maxLength: {
            value: 500,
            message: "Task description cannot exceed 500 characters",
        },
    },



    receiverName: {
        required: { value: true, message: "Required Receiver name" },
        // minLength: { value: MAXLength.minaccountNo, message: ERROR_MESSAGE.minaccountNo },
        // maxLength: { value: MAXLength.maxaccountNo, message: ERROR_MESSAGE.maxaccountNo }
    },
    receiverPhoneNo: {
        required: { value: true, message: "Required Receiver phone no" },
        // minLength: { value: MAXLength.minaccountNo, message: ERROR_MESSAGE.minaccountNo },
        // maxLength: { value: MAXLength.maxaccountNo, message: ERROR_MESSAGE.maxaccountNo }
    },
    receiverEmail: {
        required: { value: true, message: "Required Receiver email" },
        // minLength: { value: MAXLength.minaccountNo, message: ERROR_MESSAGE.minaccountNo },
        // maxLength: { value: MAXLength.maxaccountNo, message: ERROR_MESSAGE.maxaccountNo }
    },

    taskFor: { required: { value: true, message: ERROR_MESSAGE.required } },
    isBlankDoc: { required: { value: true, message: ERROR_MESSAGE.required } },


}


import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, FormHelperText, Grid, IconButton, List, ListItem, ListItemText, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks, DocumentDialogCommonHooks, PreviewPDFModal } from '../../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table'
import { API } from '../../../../../services/api-config'
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal'
import { KSCloudDownloadIcon, KSDocumentSearchIcon, KSPencilAltIcon, KSTrashIcon } from '../../../../../icons/custome-icon'

import { deleteApiCallDynamic, getApiCallDynamic, postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { CREATE, DELETE, PUT, UPDATE } from '../../../../../constants/constatnt-variable'
import { kstheme } from '../../../../../theme/theme'
import { docPreview, documentPreview } from '../../../../../helpers/file-upload-download'
import { previewDocument } from '../../../../../helpers/preview-document'
import LoanDocumentMainForm from './loan-document-main-form'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../recoil/profile-atom'


export default function LoanDocumentMain() {



    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

    const route = useNavigate()
    const location = useLocation();

    const [taskList, setTaskList] = useState([]);

    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTask,
                // param: { formId: formId, status: status },
            }).then((res) => {
                if (res?.status === 200) {
                    setTaskList(res?.data);
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, []);


    let [formValue, setFormValue] = useRecoilState(formState)

    //console.log(" TASKS " + JSON.stringify(taskList, null, 2))


    const [refreshStatus, setRefreshStatus] = useState(false)
    const [docEdit, setDocEdit] = useState();
    const [docList, setDocList] = useState([]);
    const [docListShow, setDocListShow] = useState([]);
    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getLoanDocuments,
                param: {
                    formId: formValue?.id,
                    loanCode: formValue?.loanCode
                },
            }).then((res) => {
                if (res?.status === 200) {
                    setDocList(res?.data);
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, [refreshStatus]);


    useEffect(() => {
        const updatedDocListShow = docList
            .map(doc => ({
                id: doc.id,
                documentType: doc.documentType,
                documentDescriptor: doc.documentDescriptor,
                storageKey: doc.storageKey,
                documentNote: doc.documentNote,
                fileName: doc.fileName,
                blankDocument: doc.blankDocument,
                taskTypeName: doc.taskMaster?.taskTypeName
            }))
        // .sort((a, b) => a.documentType.localeCompare(b.documentType));

        setDocListShow(updatedDocListShow);
    }, [docList]);



    const taskTypeOptions = taskList.map((task) => ({
        label: task.taskTypeName,
        value: task.taskTypeName,
    }));


    const getTaskTypeNameById = (id) => {
        const task = taskList.find(item => item.id === id);
        return task?.task.taskTypeName
    };


    // let [formValue, setFormValue] = useState([])



    let [customError, setCustomerror] = useState("")

    const [editState, setEditState] = useState(() => {
        return null
    })




    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(docList)
            let editItem = list.find((item, i) => i == editState)
            setDocEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            docList.splice(deleteState, 1)
            setDocList([...docList])
            setDeleteState(null)
        }

    }, [deleteState])

    const updateState = () => {

        setRefreshStatus(true)

    }

    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "documentName",
                header: "Document Type",
                enableColumnActions: false,

            },




            {
                accessorKey: 'documentName',
                header: 'Visibility',
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            whiteSpace: 'normal', // Allows text to wrap
                            wordBreak: 'break-word', // Break long words
                            maxWidth: '600px', // Set your desired max width
                        }}
                    >
                        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                            {row.original['documentType']}
                        </Typography>
                        {getVisibilityStatus(row.original['borrowerVisibility'], row.original['realtorsVisibility'])
                        }

                    </Box>
                ),
            },
            {
                accessorKey: "fileName",
                header: "File Name",
                enableColumnActions: false,

            },
            {
                accessorKey: "uploadDate",
                header: "Uploaded On",
                enableColumnActions: false,

            },



        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const handleClickOpen = () => {

        setDocEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)

    };



    const handleCloseDelete = async (event, row) => {
        console.log("roooo " + JSON.stringify(row, null, 2))
        if (event.target.value == 'yes') {
            //setDeleteState(row.index)


            try {
                await deleteApiCallDynamic(
                    {

                        param: { loanDocumentId: row?.original?.id },
                        path: API.createLoanDocument,
                        header: DELETE,
                        refresh: updateState
                    }
                )
            } catch (error) {
                console.error("API call failed", error);

            }
        }
    }



    useEffect(() => {
        setDocList([...docList]);
    }, [docList.length])




    const getVisibilityStatus = (borrowerVisibility, realtorsVisibility) => {
        if (borrowerVisibility && realtorsVisibility) {
            return "This is visible to both Borrower and Realtors";
        } else if (borrowerVisibility) {
            return "This is visible to Borrower";
        } else if (realtorsVisibility) {
            return "This is visible to Realtors";
        } else {
            return "This is not visible to Borrower or Realtors";
        }
    };


    const docsSubmit = async (data) => {


        console.log(" datark " + JSON.stringify(data, null, 2))

        // setCustomerror("")
        // if (editState != null) {
        //     docList[editState] = {
        //         ...docList[editState],
        //         ...data
        //     }

        //     setEditState(null)
        // } else {
        //     docList.push(data);
        // }

        // setDocList([...docList]);

        const visibility = data?.visibility || [];
        const borrowerVisibilityStatus = visibility.includes("Borrower");
        const realtorsVisibilityStatus = visibility.includes("Realtors");
        const userDefinedDocumetType = data?.customDocumentType ? true : false



        const apiCallData = {
            "id": data?.id,
            "loanCode": formValue?.loanCode,
            "documentName": data?.documentType || data?.customDocumentType,
            "fileName": data?.fileName,
            "storageKey": data?.storageKey,
            "docType": data?.docType,
            "storageDocId": data?.storageDocId,
            "description": null,
            "formId": formValue?.id,
            "userDefinedDocType": userDefinedDocumetType,
            "borrowerOnlyDocument": data?.borrowerOnlyDocument,
            "borrowerVisibility": borrowerVisibilityStatus,
            "realtorsVisibility": realtorsVisibilityStatus,
            "visibility": data?.visibility


        };

        console.log(" apiCallData " + JSON.stringify(apiCallData, null, 2))

        try {
            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: API.createLoanDocument,
                    header: docEdit?.length !== 0 ? UPDATE : CREATE,
                    refresh: updateState
                }
            )
        } catch (error) {
            console.error("API call failed", error);
            // setCustomError("An error occurred while submitting the form.");
        }
        handleClose()
    }

    const downloadLoanDocument = (row) => {
        downloadReportFromServer(API.proxyDocumentDownload, {
            id: row?.original?.storageDocId,
            serviceType: "loan",
        });
    };


    const previewLoanDocument = (row) => {

        documentPreview(API.proxyDocumentDownload, {
            id: row?.original?.storageDocId,
            serviceType: "loan",
        });
    }

    // const previewDocument = (row) => {
    //     const storageKey = row?.original?.storageKey;
    //     const fileName = row?.original?.fileName;
    //     const apiUrl = API.downloadDoc

    //     if (storageKey && fileName) {
    //         // Call docPreview to download or preview the document
    //         docPreview(apiUrl, { storageKey, fileName })
    //             .then((url) => {
    //                 console.log('Preview URL:', url);
    //             })
    //             .catch((error) => {
    //                 console.error('Error previewing the file:', error);
    //             });
    //     } else {
    //         alert('Missing storage key or file name!');
    //     }
    // };




    return (
        <>

            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box >

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mr: isMobile ? '1rem' : '3rem', mt: isMobile ? '0.5rem' : '2rem' }}>
                        <Button sx={{ width: '10%' }} variant="contained" onClick={handleClickOpen}>
                            + Add
                        </Button>
                    </Box>
                    <CustomReactMaterialTable
                        staticLoad={true}
                        staticData={docList}
                        columns={tableColumn}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSorting={false}
                        enableToolbarInternalActions={false}
                        // renderTopToolbarCustomActions={() => (

                        // )}
                        enableRowActions={true}
                        renderRowActions={
                            ({ row }) => {

                                return (
                                    <Stack direction={'row'}>



                                        <IconButton onClick={() => { setEditState(row.index) }}>
                                            <KSPencilAltIcon />
                                        </IconButton>

                                        <IconButton
                                            color="primary"
                                            onClick={() => previewLoanDocument(row)}  // Pass the row data when clicked
                                            disabled={!row?.original?.fileName}
                                        >
                                            <KSDocumentSearchIcon />
                                        </IconButton>

                                        <ConfirmationModel
                                            body={<IconButton
                                                id={row.index} onClick={() => {
                                                    // setDeleteState(row.index)
                                                }}>
                                                <KSTrashIcon />
                                            </IconButton>}
                                            message={`Are you sure want remove  ${row?.original?.documentType} - Savings?`}
                                            title={'Delete Document'}
                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                        />

                                    </Stack>
                                )
                            }
                        }
                    />
                    {customError && <FormHelperText error>{customError}</FormHelperText>}
                </Box>







                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>

                </Box>
            </Stack>

            <DocumentDialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={docEdit?.length !== 0 ? `Update Task Documents` : `Add Task Documents`}
                body={
                    <LoanDocumentMainForm
                        onSubmit={docsSubmit}
                        editItem={docEdit}
                        // setUploadData={setUploadData}
                        // hasPartner={formValue?.hasPartner}
                        handleClose={handleClose}
                        docList={taskTypeOptions}
                        refreshStatus={refreshStatus}
                        setRefreshStatus={setRefreshStatus}
                        formId={formValue?.id}
                        previewDocument={previewDocument}
                    />
                }
            />


        </>
    )
}




// import {
//     FormControl,
//     InputLabel,
//     Select,
//     MenuItem,
//     Checkbox,
//     ListItemText,
//     OutlinedInput,
//     Box,
// } from '@mui/material';
// import React from 'react';

// const MultiSelectNotAllDropDown = ({ field, options, errors, label }) => {
//     return (
//         <Box mb={1} mt={1}>
//             <FormControl fullWidth error={!!errors}>
//                 <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
//                 <Select
//                     {...field}
//                     multiple
//                     input={<OutlinedInput label={label} />}
//                     //  renderValue={(selected) => selected.join(', ')}
//                     renderValue={(selected) => {
//                         if (selected.length === 0) {
//                             return 'Select option'; // Display placeholder when nothing is selected
//                         }
//                         return selected.join(', '); // Display selected items joined by a comma
//                     }}
//                     MenuProps={{
//                         PaperProps: {
//                             style: {
//                                 maxHeight: 224,
//                                 width: 250,
//                             },
//                         },
//                     }}
//                     onChange={(event) => {
//                         // Make sure to handle `event.target.value` properly
//                         const value = event.target.value;
//                         field.onChange(value);
//                         //console.log(value);
//                     }}
//                     value={field.value || []} // Ensure default value is an empty array
//                 >
//                     {options.map((option) => (
//                         <MenuItem key={option} value={option}>
//                             <Checkbox checked={field.value ? field.value.indexOf(option) > -1 : false} />
//                             <ListItemText primary={option} />
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </FormControl>
//         </Box>
//     );
// };
// export default MultiSelectNotAllDropDown;

// import {
//     FormControl,
//     InputLabel,
//     Select,
//     MenuItem,
//     Checkbox,
//     ListItemText,
//     OutlinedInput,
//     Box,
// } from '@mui/material';
// import React from 'react';

// const MultiSelectNotAllDropDown = ({ field, options, errors, label }) => {
//     return (
//         <Box mb={1} mt={1}>
//             <FormControl fullWidth error={!!errors}>
//                 <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
//                 <Select
//                     {...field}
//                     multiple
//                     input={<OutlinedInput label={label} />}
//                     renderValue={(selected) => {
//                         if (selected.length === 0) {
//                             return 'Select option';
//                         }
//                         return selected.join(', ');
//                     }}
//                     MenuProps={{
//                         PaperProps: {
//                             style: {
//                                 maxHeight: 224,
//                                 width: 250,
//                             },
//                         },
//                     }}
//                     onChange={(event) => {
//                         const value = event.target.value;
//                         field.onChange(value);
//                     }}
//                     value={field.value || []} 
//                 >
//                     {options.map((option) => (
//                         <MenuItem key={option} value={option}>
//                             <Checkbox checked={field.value ? field.value.indexOf(option) > -1 : false} />
//                             <ListItemText primary={option} />
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </FormControl>
//         </Box>
//     );
// };

// export default MultiSelectNotAllDropDown;
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Box,
} from '@mui/material';
import React from 'react';

const MultiSelectNotAllDropDown = ({ field, options, errors, label }) => {
    return (
        <Box mb={1} mt={1}>
            <FormControl fullWidth error={!!errors}>
                <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
                <Select
                    {...field}
                    multiple
                    displayEmpty
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) => {
                        // Check if nothing is selected
                        if (!selected || selected.length === 0) {
                            return <em>Select option</em>;
                        }
                        return selected.join(', '); // Show selected options
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 224,
                                width: 250,
                            },
                        },
                    }}
                    onChange={(event) => {
                        const value = event.target.value;
                        field.onChange(value); // Handle the change in selected value
                    }}
                    value={field.value || []} // Ensure value is always an array
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox checked={field.value ? field.value.indexOf(option) > -1 : false} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default MultiSelectNotAllDropDown;

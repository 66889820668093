// import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
// import React from 'react'

// export default function MultiSelectDropDown({ options, value, setValue, ...props }) {



//     const ITEM_HEIGHT = 48;
//     const ITEM_PADDING_TOP = 8;
//     const MenuProps = {
//         PaperProps: {
//             style: {
//                 maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             },
//         },
//     };
//     const handleChange = (event) => {
//         const {
//             target: { value },
//         } = event;
//         setValue(
//             typeof value === 'string' ? value.split(',') : value,
//         );
//     };

//     return (
//         <FormControl sx={{ width: '100%' }}>
//             <Select
//                 displayEmpty
//                 multiple
//                 value={value}
//                 onChange={handleChange}
//                 input={<OutlinedInput />}
//                 renderValue={(selected) => selected.join(', ')}
//                 MenuProps={MenuProps}
//             >
//                 {options.map((item) => (
//                     <MenuItem key={item} value={item}>
//                         <Checkbox checked={value.indexOf(item) > -1} />
//                         <ListItemText primary={item} />
//                     </MenuItem>
//                 ))}
//             </Select>
//         </FormControl>
//     )
// }

// // import {
// //     FormControl,
// //     InputLabel,
// //     Select,
// //     MenuItem,
// //     Checkbox,
// //     ListItemText,
// //     OutlinedInput,
// // } from '@mui/material';
// // import React from 'react';

// // const MultiSelectDropDown = ({ field, options, errors, label }) => {
// //     return (
// //         <FormControl sx={{ m: 1, width: 300 }} error={!!errors}>
// //             <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
// //             <Select
// //                 {...field}
// //                 multiple
// //                 input={<OutlinedInput label={label} />}
// //                 renderValue={(selected) => selected.join(', ')}
// //                 MenuProps={{
// //                     PaperProps: {
// //                         style: {
// //                             maxHeight: 224,
// //                             width: 250,
// //                         },
// //                     },
// //                 }}
// //                 onChange={(event) => {
// //                     // Make sure to handle `event.target.value` properly
// //                     const value = event.target.value;
// //                     field.onChange(value);
// //                     //console.log(value);
// //                 }}
// //                 value={field.value || []} // Ensure default value is an empty array
// //             >
// //                 {options.map((option) => (
// //                     <MenuItem key={option} value={option}>
// //                         <Checkbox checked={field.value ? field.value.indexOf(option) > -1 : false} />
// //                         <ListItemText primary={option} />
// //                     </MenuItem>
// //                 ))}
// //             </Select>
// //         </FormControl>
// //     );
// // };

// // export default MultiSelectDropDown;
// import {
//     FormControl,
//     InputLabel,
//     Select,
//     MenuItem,
//     Checkbox,
//     ListItemText,
//     OutlinedInput,
// } from '@mui/material';
// import React from 'react';

// const MultiSelectDropDown = ({ field, options, errors, label }) => {
//     return (
//         // <FormControl sx={{ m: 1, width: '100%' }} error={!!errors}>
//         //     <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
//         //     <Select
//         //         {...field}
//         //         multiple
//         //         input={<OutlinedInput label={label} />}
//         //         renderValue={(selected) => selected.join(', ')}
//         //         MenuProps={{
//         //             PaperProps: {
//         //                 style: {
//         //                     maxHeight: 224,
//         //                     width: 250,
//         //                 },
//         //             },
//         //         }}
//         //         onChange={(event) => {
//         //             const value = event.target.value;
//         //             field.onChange(value);
//         //             // Log the selected values
//         //             console.log("Selected values:", value);
//         //         }}
//         //         value={field.value || []} // Ensure default value is an empty array
//         //     >
//         //         {options.map((option) => (
//         //             <MenuItem key={option} value={option}>
//         //                 <Checkbox checked={field.value ? field.value.indexOf(option) > -1 : false} />
//         //                 <ListItemText primary={option} />
//         //             </MenuItem>
//         //         ))}
//         //     </Select>
//         // </FormControl>
//         <FormControl sx={{ m: 1, width: '100%' }} error={!!errors}>
//             <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
//             <Select
//                 {...field}
//                 multiple
//                 // input={<OutlinedInput label={label} />}
//                 // Conditionally render either 'Select option' or the selected values
//                 renderValue={(selected) => {
//                     if (selected.length === 0) {

//                     }
//                     return <>Select option</>; // Display placeholder when nothing is selected
//                     //return selected.join(', '); // Display selected items joined by comma
//                 }}
//                 MenuProps={{
//                     PaperProps: {
//                         style: {
//                             maxHeight: 224,
//                             width: 250,
//                         },
//                     },
//                 }}
//                 onChange={(event) => {
//                     const value = event.target.value;
//                     field.onChange(value);
//                     // Log the selected values
//                     console.log("Selected values:", value);
//                 }}
//                 value={field.value || []} // Ensure default value is an empty array
//             >
//                 {options.map((option) => (
//                     <MenuItem key={option} value={option}>
//                         <Checkbox checked={field.value ? field.value.indexOf(option) > -1 : false} />
//                         <ListItemText primary={option} />
//                     </MenuItem>
//                 ))}
//             </Select>
//         </FormControl>

//     );
// };

// export default MultiSelectDropDown;
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

const MultiSelectDropDown = ({ field, options, selectAll }) => {
    const selectedValues = field.value || []; // Ensure field.value is an array

    const [internalSelectAll, setInternalSelectAll] = useState(selectAll); // Internal state to track selectAll

    // Effect to handle selectAll functionality
    useEffect(() => {
        // Check if selectAll state is true and if selectedValues are not the same as options
        if (selectAll && selectedValues.length !== options.length) {
            field.onChange(options); // Select all options
            setInternalSelectAll(true); // Update internal state
        }
        // Handle deselectAll when selectAll is false and everything was previously selected
        else if (!selectAll && internalSelectAll && selectedValues.length === options.length) {
            field.onChange([]); // Deselect all options
            setInternalSelectAll(false); // Update internal state
        }
    }, [selectAll, selectedValues, options, field, internalSelectAll]); // internalSelectAll ensures the effect runs only when necessary

    return (
        <Box mb={1} mt={1}>
            <FormControl fullWidth>
                {/* <InputLabel id="multi-select-label">Select option</InputLabel> */}
                <Select
                    {...field}
                    multiple
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return 'Select option'; // Display placeholder when nothing is selected
                        }
                        return selected.join(', '); // Display selected items joined by a comma
                    }}
                    onChange={(event) => {
                        const value = event.target.value;
                        field.onChange(value); // Update selected values
                    }}
                    value={selectedValues} // Ensure default value is an empty array
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox checked={selectedValues.indexOf(option) > -1} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default MultiSelectDropDown;

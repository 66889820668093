// import { Box, Button } from "@mui/material";
// import React from "react";
// import { CREATE, ERROR_MESSAGE, MODAL_ACTION } from "../../../constants/constatnt-variable";
// import { Controller, useForm } from "react-hook-form";
// import CustomInput from "../../../helpers/custom-input";

// export default function DocumentLibraryForm({
//     data,
//     action = CREATE,
//     onSubmit,
// }) {
//     const DemoRe1uestNoteRules = {
//         note: {
//             required: { value: true, message: ERROR_MESSAGE.required },
//         },

//     }
//     const { control, handleSubmit, formState: { errors } } = useForm({
//         defaultValues: {
//             note: data?.note,
//             id: data?.id
//         }
//     })

//     return (
//         <form onSubmit={handleSubmit(onSubmit)}>
//             <Controller
//                 name='note'
//                 control={control}
//                 rules={DemoRe1uestNoteRules.note}
//                 render={({ field }) => (
//                     <CustomInput
//                         {...field}
//                         label={'Notes'}
//                         required
//                         placeholder={'This loan is in the process'}
//                         size="large"
//                         multiline
//                         rows={10}
//                         error={errors?.note?.message}
//                     />
//                 )}
//             />

//             <Box display="flex" justifyContent="flex-end" m={1}>
//                 <Button type='submit' id={action} variant="contained" onClick={handleSubmit}>
//                     {/* {MODAL_ACTION[action]} */} Save
//                 </Button>
//             </Box>
//         </form>
//     );
// }


import { AttachMoney, } from '@mui/icons-material'
import { Box, Button, FormHelperText, FormLabel, InputLabel, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
    ACCOUNT_TYPE,
    ASSET_OWNED_BY,
    ASSET_OWNED_BY_WITHOUT_PARTNER,
    BLANK_DOCUMENT_STATUS,
    STATUS_OF_REALTOR,
    TASK_VENDOR
} from '../../../constants/constatnt-variable'
import CustomInput from '../../../helpers/custom-input'
import CustomInputSideCheckbox from '../../../helpers/custom-input-side-checkbox'
import CustomDropDown from '../../../helpers/dropdown/custom-dropdown'
import { Controller, useForm, useWatch } from 'react-hook-form'
import CustomRadioChip from '../../../helpers/radio-chip'
import RadioButtonGroupDoc from '../../../helpers/radio-button-group-doc'
import { TaskTypeValidation } from '../../../constants/validation-rules'
import { KSCloudUploadIcon } from '../../../icons/custome-icon'
import { getApiCallDynamic, uploadDocCustom } from '../../../services/api-service'
import { API } from '../../../services/api-config'
import { toastNotify } from '../../../helpers/alert-msg'


export default function DocumentLibraryForm({ handleClose, onSubmit, editItem, hasPartner, setUploadData, docList, refreshStatus, setRefreshStatus }) {



    // console.error('task master fetched inside doc library :', JSON.stringify(docList, null, 2));
    // let [taskList, setDocList] = useState([
    //     { 'taskFor': 'sdas', 'taskDescription': 'doc1', 'taskType': 'task11' },
    //     { 'taskFor': 'new', 'taskDescription': 'doc2sdsada', 'taskType': 'tsdsadasdasd' },
    //     { 'taskFor': 'world', 'taskDescription': 'doc2', 'taskType': 'task95' }])


    // const taskTypeOptions = taskList.map((task) => ({
    //     label: task.taskType, // Use taskType as the label
    //     value: task.taskType, // Use taskType as the value
    // }));

    //  console.log(" Fetched data " + JSON.stringify(editItem, null, 2))

    const [fileNameToView, setFileNameToView] = useState(editItem?.fileName);

    console.log(" First time " + JSON.stringify(editItem, null, 2))

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {

            id: editItem?.id,
            documentNote: editItem?.documentNote,
            documentType: editItem?.documentType,
            taskType: editItem?.taskMaster?.id,
            blankDocument: editItem?.blankDocument === true ? 'Yes' : 'No',
            documentDescriptor: editItem?.documentDescriptor,
            //fileName: editItem?.fileName,
            //storageKey: editItem?.storageKey,
        }
    })

    const {
        blankDocument,
        brokerDocId,
        documentType,
        taskType,
        documentDescriptor,
        // fileName,

    } = useWatch({ control });


    const [file, setFile] = useState(null);
    const [uploadResponse, setUploadResponse] = useState(null);
    // const [taskTypeId] = useState(121);

    // const [documentType] = useState('pay slip');
    // const [documentDescriptor] = useState('pay slip for month jun');





    const handleFileChange = async (event) => {

        if (blankDocument === 'Yes') {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);

            const uploadPath = 'https://dev.kramasoft.com/ks-loan/api/v1/document-lib-doc'
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('taskTypeId', taskType);
            formData.append('documentType', documentType);
            formData.append('documentDescriptor', documentDescriptor);
            formData.append('serviceType', 'DocLib');


            console.log('form data before upload:', JSON.stringify(formData, null, 2));
            console.log('path before upload:', JSON.stringify(uploadPath, null, 2));
            try {
                // Call the generic uploadFile function
                const response = await uploadDocCustom({ formData, path: uploadPath });

                setUploadData(response.data)
                setFileNameToView(response.data.fileName)
                setUploadResponse(response.data); // Store the response for the second API call
                toastNotify("success", 'File uploaded successfully');
                setRefreshStatus(!refreshStatus)
            } catch (error) {
                console.error('Error uploading file:', error);
                toastNotify("error", 'Error uploading file');

            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Box width={'100%'} my={2} >
                <Box>

                    <Controller
                        name='taskType'
                        control={control}
                        rules={TaskTypeValidation.taskType}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Select the Task Type'}
                                required
                                //state={taskType}
                                option={docList}
                                error={errors?.taskType?.message}
                            />
                        )}
                    />

                    <Controller
                        name="documentType"
                        control={control}
                        rules={TaskTypeValidation.documentType}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'Enter document type type'}
                                type={'text'} label={'Enter Document Type'} required error={errors?.documentType?.message} />
                        )}
                    />

                    <Controller
                        name="documentDescriptor"
                        control={control}
                        rules={TaskTypeValidation.documentDescriptor}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'None'}
                                type={'text'} label={'Enter Document Descriptor'} required error={errors?.documentDescriptor?.message} />
                        )}
                    />

                    <Controller
                        name="documentNote"
                        control={control}
                        rules={TaskTypeValidation.documentNote}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'Enter the instructions for filling the document'}
                                type={'text'} label={'Note for Filling the Document'} required error={errors?.documentNote?.message} />
                        )}
                    />



                    <Controller
                        name="blankDocument"
                        control={control}
                        rules={TaskTypeValidation.isBlankDoc}
                        render={({ field }) => (
                            <RadioButtonGroupDoc
                                {...field}
                                field={field}
                                required
                                direction="row"
                                labelComponent={<FormLabel>{`Is Blank document available?`}</FormLabel>}
                                data={BLANK_DOCUMENT_STATUS}
                                error={errors?.isBlankDoc?.message}
                            />
                        )}
                    />






                </Box>

                <Box marginY={4} textAlign={'right'}>

                    {
                        blankDocument === 'Yes' &&

                        <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                            my={2}
                            justifyContent={"space-between"}
                        >
                            {/* <InputLabel>{`Document to Sign`}
                                                <Typography component={'span'} color={'error'}>{`*`}</Typography>
                                            </InputLabel> */}


                            <Button
                                component="label"
                                size="small"
                                // {...field}
                                // value={brokerFileID}
                                startIcon={<KSCloudUploadIcon color={"primary"} />}
                                sx={{
                                    mb: 2,
                                    display: 'flex',           // Make button a flex container
                                    flexDirection: 'column',   // Stack elements vertically
                                    alignItems: 'center',      // Center align them horizontally
                                    gap: 1                     // Add space between items
                                }}
                            >
                                <input
                                    onChange={handleFileChange}
                                    hidden
                                    accept="image/*"
                                    type="file"
                                />
                                <Typography paragraph>
                                    {fileNameToView ? `Reupload` : `Upload`}
                                </Typography>

                                <Typography paragraph> {fileNameToView}</Typography>
                            </Button>

                        </Stack>



                    }

                    {/* <Button type='submit' variant='contained' >
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button> */}

                    <Button
                        type="button"
                        onClick={handleClose}
                        //variant="contained"
                        sx={{
                            //  width: '200px', // Set a fixed width for the button
                            width: '50%', // Alternatively, set a percentage width
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        type="submit"
                        variant="contained"
                        disabled={blankDocument === 'Yes' && !fileNameToView} // Disable if both are null or falsy
                        sx={{
                            //  width: '200px', // Set a fixed width for the button
                            width: '50%', // Alternatively, set a percentage width
                        }}
                    >
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button>
                </Box>
            </Box>
        </form>

    )
}
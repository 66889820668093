import React from "react";
import {
    Box,
    Button,
    Stack,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    FormHelperText,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { profileState } from "../../recoil/profile-atom";
import { ROLE_LIST } from "../../constants/constatnt-variable";

export default function ThirdPartyResolveConfirm({ handleClose, onConfirm, thirdPartyUser = false }) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            option: "",
        },
    });

    const onSubmit = (data) => {
        onConfirm(data.option);
        handleClose();
    };
    let [profileData, setProfileData] = useRecoilState(profileState);
    console.log(" ROLLL" + profileData?.role)
    return (
        <Box
            sx={{
                // minHeight: '70vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Stack spacing={2} sx={{ width: '100%' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <Controller
                        name="option"
                        control={control}
                        rules={{ required: "Please select an option" }}
                        render={({ field }) => (
                            <RadioGroup {...field} row>
                                <FormControlLabel
                                    value="RESUMELATER"
                                    control={<Radio />}
                                    label="Resume Task Later"
                                />
                                <FormControlLabel
                                    value="PARTIALCOMPLETE"
                                    control={<Radio />}
                                    label="Send Response and Mark it Not Completed"
                                />
                                <FormControlLabel
                                    value="Resolved"
                                    control={<Radio />}
                                    label="Mark Task as Resolved"
                                />
                            </RadioGroup>
                        )}
                    />
                    {errors.option && (
                        <FormHelperText error>
                            {errors.option.message}
                        </FormHelperText>
                    )} */}
                    {((profileData.role === ROLE_LIST.mlo || profileData.role === ROLE_LIST.mloOwner || profileData.role === ROLE_LIST.broker)) && (
                        <Controller
                            name="option"
                            control={control}
                            rules={{ required: "Please select an option" }}
                            render={({ field }) => (
                                <RadioGroup {...field} row>
                                    <FormControlLabel
                                        value="RESUMELATER"
                                        control={<Radio />}
                                        label="Resume Task Later"
                                    />
                                    <FormControlLabel
                                        value="INCOMPLETE"
                                        control={<Radio />}
                                        label="Send Response and Mark it Not Completed"
                                    />
                                    <FormControlLabel
                                        value="RESOLVED"
                                        control={<Radio />}
                                        label="Mark Task as Resolve"
                                    />
                                </RadioGroup>
                            )}
                        />
                    )}
                    {(!(profileData.role === ROLE_LIST.mlo || profileData.role === ROLE_LIST.mloOwner || profileData.role === ROLE_LIST.broker
                        || profileData.role === ROLE_LIST.borrower
                    )) && (
                            <Controller
                                name="option"
                                control={control}
                                rules={{ required: "Please select an option" }}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="RESUMELATER"
                                            control={<Radio />}
                                            label="Resume Task Later"
                                        />
                                        <FormControlLabel
                                            value="INCOMPLETE"
                                            control={<Radio />}
                                            label="Send Response and Continue"
                                        />
                                        <FormControlLabel
                                            value="COMPLETED"
                                            control={<Radio />}
                                            label="Mark Task as Completed"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        )}

                    <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2} mt={2}>
                        <Button type="submit" variant="contained">
                            OK
                        </Button>
                        <Button variant="text" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Stack>
                </form>
            </Stack>
        </Box>
    );
}

// import { Button, Stack, Typography } from '@mui/material'
// import React, { useState } from 'react'
// import { KSCloudUploadIcon } from '../../icons/custome-icon'
// import { uploadFileWithToken } from '../../services/api-service';
// import { API } from '../../services/api-config';
// import { toastNotify } from '../../helpers/alert-msg';


// const ThirdPartyUploadCard = ({ item, formId, accessCode }) => {

//     console.log(" Check item " + item)
//     const [localFileDetails, setLocalFileDetails] = useState([]);

//     const fileUpload = async (event, item) => {
//         event.preventDefault();

//         const file = event.target.files[0];
//         if (file) {
//             try {
//                 const res = await uploadFileWithToken({
//                     formId: formId,
//                     file: file,
//                     serviceType: "task",
//                     path: API.proxyDocumentThirdPartyOthersUpload,
//                     token: accessCode,
//                 });

//                 if (res.status === 201) {
//                     toastNotify("success", res.data.message);
//                     setLocalFileDetails(prevFiles => [
//                         ...prevFiles,
//                         { item, file, message: res.data.message }
//                     ]);
//                 } else {
//                     toastNotify("error", res.data.message);
//                 }
//             } catch (error) {
//                 console.error("Upload failed: ", error);
//                 toastNotify("error", "File upload failed.");
//             }
//         } else {
//             console.log("No file selected");
//         }
//     };















//     return (
//         <div>
//             {/* <Typography>{item}</Typography>
//             <Button
//                 component="label"
//                 size="small"
//                 startIcon={<KSCloudUploadIcon color={"primary"} />}
//             >
//                 <input
//                     onChange={(e) => fileUpload(e, item)}
//                     hidden
//                     accept="image/*"
//                     type="file"
//                 />
//                 Upload
//             </Button> */}
//             <Stack
//                 direction="row"
//                 justifyContent="space-between"
//                 alignItems="center"
//                 spacing={2}  // Optional: Adjust spacing between the items if needed
//             >
//                 <Typography>{item?.documentType}</Typography>
//                 <Button
//                     component="label"
//                     size="small"
//                     startIcon={<KSCloudUploadIcon color={"primary"} />}
//                 >
//                     <input
//                         onChange={(e) => fileUpload(e, item)}
//                         hidden
//                         accept="image/*"
//                         type="file"
//                     />
//                     Upload
//                 </Button>
//             </Stack>

//         </div>



//     )
// }

// export default ThirdPartyUploadCard

import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { KSCloudUploadIcon, KSDocumentSearchIcon } from '../../icons/custome-icon'
import { uploadFileWithToken } from '../../services/api-service';
import { API } from '../../services/api-config';
import { toastNotify } from '../../helpers/alert-msg';
import { docPreviewResolve, downloadReportFromServer, downloadReportFromServerThirdParty } from '../../helpers/file-upload-download';


const ThirdPartyUploadCard = ({ item, formId, accessCode, taskCode, formData, setUploadFile }) => {
    // console.log("Check item " + JSON.stringify(formData, null, 2));
    const [localFileDetails, setLocalFileDetails] = useState(null);  // Now it's a single object for simplicity

    const fileUpload = async (event, item) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            try {
                const res = await uploadFileWithToken({

                    file: file,
                    serviceType: "task",
                    formId: formId,
                    thirdPartyTaskCode: taskCode,
                    path: API.proxyDocumentThirdPartyOthersUpload,
                    token: accessCode,
                });

                // console.log(" Upload data " + res)


                if (res.status === 201) {
                    toastNotify("success", res.data.message);


                    const updatedData = {
                        ...res?.data,
                        uploadId: item?.id
                    };

                    console.log(" Updated Data " + JSON.stringify(updatedData, null, 2)

                    )

                    setUploadFile(updatedData)
                    // setLocalFileDetails({ item, file, message: res.data.message });
                    setLocalFileDetails(res.data);
                } else {
                    toastNotify("error", res.data.message);
                }
            } catch (error) {
                console.error("Upload failed: ", error);
                toastNotify("error", "File upload failed.");
            }
        } else {
            console.log("No file selected");
        }
    };
    console.log(" local file details " + JSON.stringify(localFileDetails, null, 2))

    const downloadDocument = (item) => {

        const apiUrl = API.proxyDocumentDownloadThirdParty
        const token = accessCode
        let storageKey
        let fileName
        let docId

        if (localFileDetails === null) {
            storageKey = item?.resolvedDocStorageKey
            fileName = item?.resolvedDocFileName
            docId = item?.resolvedDocId

        } else {
            storageKey = localFileDetails?.storageKey || item?.storageKey
            fileName = localFileDetails?.fileName
            docId = localFileDetails?.docId

        }

        const param = {
            storageKey: storageKey,
            // fileName: fileName,
            id: docId,
            thirdPartyTaskCode: taskCode

        }

        if (storageKey && fileName) {
            // Call docPreview to download or preview the document
            docPreviewResolve(apiUrl, param, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        } else {
            //alert('Missing storage key or file name!');
        }



    };
    console.log(" Item details " + JSON.stringify(item, null, 2))

    return (
        <div>
            <Box sx={{ border: '1px solid lightgray', pX: 1 }} mb={2} padding={1}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >

                    <Box mt={1} mb={1} >
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {item?.documentType}
                        </Typography>

                        {/* {localFileDetails?.fileName && (
                            <Typography variant="body2" color="grey" >
                                {localFileDetails?.fileName}
                            </Typography>
                        )} */}
                        <Typography variant="body2" color="grey">
                            {localFileDetails?.fileName || item?.resolvedDocFileName}
                        </Typography>
                    </Box>

                    <Box>



                        <IconButton
                            color="primary"

                            //   disabled={!localFileDetails?.fileName}
                            onClick={() => downloadDocument(item)}
                        >
                            <KSDocumentSearchIcon />
                        </IconButton>
                        <Button
                            component="label"
                            size="small"
                            startIcon={<KSCloudUploadIcon color={"primary"} />}
                        >
                            <input
                                onChange={(e) => fileUpload(e, item)}
                                hidden
                                // accept="image/*"
                                type="file"
                            />
                            Upload
                        </Button>
                    </Box>
                    {/* {localFileDetails && localFileDetails.item === item && (
                    <Typography variant="body2" color="green">
                        {localFileDetails.message}
                    </Typography>
                )} */}

                </Stack>
            </Box>

            {/* Conditionally render success message */}
            {/* {localFileDetails && localFileDetails.item === item && (
                <Typography variant="body2" color="green">
                    {localFileDetails.message}
                </Typography>
            )} */}
        </div>
    );
}

export default ThirdPartyUploadCard;

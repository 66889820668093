// import { Box, Button } from "@mui/material";
// import React from "react";
// import { CREATE, ERROR_MESSAGE, MODAL_ACTION } from "../../../constants/constatnt-variable";
// import { Controller, useForm } from "react-hook-form";
// import CustomInput from "../../../helpers/custom-input";

// export default function DocumentLibraryForm({
//     data,
//     action = CREATE,
//     onSubmit,
// }) {
//     const DemoRe1uestNoteRules = {
//         note: {
//             required: { value: true, message: ERROR_MESSAGE.required },
//         },

//     }
//     const { control, handleSubmit, formState: { errors } } = useForm({
//         defaultValues: {
//             note: data?.note,
//             id: data?.id
//         }
//     })

//     return (
//         <form onSubmit={handleSubmit(onSubmit)}>
//             <Controller
//                 name='note'
//                 control={control}
//                 rules={DemoRe1uestNoteRules.note}
//                 render={({ field }) => (
//                     <CustomInput
//                         {...field}
//                         label={'Notes'}
//                         required
//                         placeholder={'This loan is in the process'}
//                         size="large"
//                         multiline
//                         rows={10}
//                         error={errors?.note?.message}
//                     />
//                 )}
//             />

//             <Box display="flex" justifyContent="flex-end" m={1}>
//                 <Button type='submit' id={action} variant="contained" onClick={handleSubmit}>
//                     {/* {MODAL_ACTION[action]} */} Save
//                 </Button>
//             </Box>
//         </form>
//     );
// }



import { Box, Button, FormLabel, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {

    BORROWER_ONLY_STATUS,

} from '../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../helpers/custom-input'

import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import { Controller, useForm, useWatch } from 'react-hook-form'

import RadioButtonGroupDoc from '../../../../../helpers/radio-button-group-doc'
import { LoanDocumentValidation, TaskTypeValidation } from '../../../../../constants/validation-rules'
import { KSCloudUploadIcon, KSDocumentSearchIcon } from '../../../../../icons/custome-icon'
import { uploadFile } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { toastNotify } from '../../../../../helpers/alert-msg'

import MultiSelectNotAllDropDown from '../../../../../helpers/dropdown/multi-select-noall-dropdown'
import { docPreview, documentPreview } from '../../../../../helpers/file-upload-download'


export default function LoanDocumentMainForm({
    handleClose,
    onSubmit,
    editItem,

    docList,
    formId,

}) {



    const [uploadData, setUploadData] = useState(null);








    const [fileNameToView, setFileNameToView] = useState(editItem?.fileName);




    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: {

            id: editItem?.id,

            documentType: editItem?.userDefinedDocType === true ? '' : editItem?.documentName || '',
            customDocumentType: editItem?.userDefinedDocType === true ? editItem?.documentName : '',

            taskType: editItem?.taskMaster?.id,
            borrowerOnlyDocument: editItem?.borrowerOnlyDocument,
            documentDescriptor: editItem?.documentDescriptor,
            visibility: [
                editItem?.borrowerVisibility ? 'Borrower' : '',
                editItem?.realtorsVisibility ? 'Realtors' : ''
            ].filter(Boolean),
            userDefinedDocType: editItem?.documentType ? false : true,

            fileName: editItem?.fileName,
            storageKey: editItem?.storageKey,
            storageDocId: editItem?.storageDocId,
            docType: editItem?.docType

        }
    })

    const {

        documentType,

        customDocumentType,


    } = useWatch({ control });


    useEffect(() => {
        if (uploadData) {
            setValue('fileName', uploadData?.fileName)
            setValue('storageKey', uploadData?.storageKey)
            setValue('docType', uploadData?.docType)
            setValue('storageDocId', uploadData?.docId)

        }


    }, [uploadData])


    const [file, setFile] = useState(null);
    const [uploadResponse, setUploadResponse] = useState(null);

    const [titles, setTitles] = useState(null)

    const fileUpload = async (event) => {
        let file = event.target.files[0];
        if (file) {
            const response = await uploadFile({
                formId: formId,
                file: file,
                serviceType: "loan",
                path: API.proxyDocumentOthersUpload,
            });

            if (response.status === 201) {

                const modifiedResponseData = {
                    ...response.data,
                    docType: 'Local'
                };

                // Set the modified data
                setUploadData(modifiedResponseData);
                // setUploadData(response.data)
                setFileNameToView(response.data.fileName)
                setUploadResponse(modifiedResponseData);
                toastNotify("success", 'File uploaded successfully');


            } else {
                toastNotify("error", res.data.message);
            }
        } else {
            console.log("not found");
        }
    };

    const isUploadDisabled = !documentType && !customDocumentType;
    console.log(" uploadresponse " + JSON.stringify(uploadResponse, null, 2))


    const previewDocument = (row) => {

        documentPreview(API.proxyDocumentDownload, {
            id: uploadData?.docId,
            serviceType: "loan",
        });

    }


    // const previewDocument = (row) => {
    //     console.log(" hhhhh " + JSON.stringify(row, null, 2))
    //     const storageKey = row?.storageKey;
    //     const fileName = row?.fileName;
    //     const apiUrl = API.downloadDoc

    //     if (storageKey && fileName) {
    //         // Call docPreview to download or preview the document
    //         docPreview(apiUrl, { storageKey, fileName })
    //             .then((url) => {
    //                 console.log('Preview URL:', url);
    //             })
    //             .catch((error) => {
    //                 console.error('Error previewing the file:', error);
    //             });
    //     } else {
    //         alert('Missing storage key or file name!');
    //     }
    // };



    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Box width={'100%'} my={3} >
                <Box>
                    <Controller
                        name='documentType'
                        control={control}
                        rules={LoanDocumentValidation.documentType}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Select Document Type'}
                                required
                                state={documentType}
                                option={docList}
                                error={errors?.documentType?.message}
                            />
                        )}
                    />




                    <Controller
                        name="customDocumentType"
                        control={control}
                        rules={LoanDocumentValidation.customDocumentType}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'Enter document type'}
                                type={'text'} label={'Enter Document Type'}


                                error={errors?.customDocumentType?.message} />

                        )}
                    />



                    <Controller
                        name="visibility"
                        control={control}
                        rules={LoanDocumentValidation.visibility}
                        required
                        render={({ field }) => (
                            <MultiSelectNotAllDropDown
                                field={{
                                    ...field,
                                    value: field.value || [],
                                }}
                                options={["Borrower", "Realtors"]}
                                errors={errors.visibility}
                            />
                        )}
                    />



                    <Controller
                        name="borrowerOnlyDocument"
                        control={control}
                        rules={LoanDocumentValidation.borrowerOnlyDocument}
                        render={({ field }) => (
                            <RadioButtonGroupDoc
                                {...field}
                                field={field}
                                required
                                direction="row"
                                labelComponent={<FormLabel>{` Is this a Borrower Document?`}</FormLabel>}
                                data={BORROWER_ONLY_STATUS}
                                error={errors?.borrowerOnlyDocument?.message}
                            />
                        )}
                    />
                    <Box
                        sx={{
                            border: '1px solid lightGrey',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '8px',
                        }}
                    >
                        <Typography>
                            {uploadResponse?.fileName || editItem?.fileName}
                        </Typography>

                        <IconButton
                            color="primary"
                            onClick={() => previewDocument(uploadResponse)}
                        // disabled={!uploadResponse?.fileName}
                        >
                            <KSDocumentSearchIcon />
                        </IconButton>
                    </Box>






                </Box>

                <Box marginY={4} textAlign={'right'}>




                    <Stack
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        my={2}
                        justifyContent={"space-between"}
                    >

                        <KSCloudUploadIcon color={"primary"} />
                        <Button
                            component="label"
                            size="small"
                            disabled={isUploadDisabled}
                        >
                            <input
                                onChange={fileUpload}
                                hidden
                                accept="image/*"
                                type="file"
                            />
                            <Typography >
                                {fileNameToView ? `Reupload` : `Upload`}
                            </Typography>



                        </Button>

                        <Button
                            type="button"
                            onClick={handleClose}
                            //variant="contained"
                            sx={{
                                //  width: '200px', 
                                width: '50%',
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            // disabled={isUploadDisabled} 
                            sx={{
                                //  width: '200px', 
                                width: '50%',
                            }}
                        >
                            {editItem?.length !== 0 ? 'Update' : 'Add'}
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </form>

    )
}



import { AttachMoney, } from '@mui/icons-material'
import { Box, Button, FormLabel } from '@mui/material'
import React from 'react'
import {
    ACCOUNT_TYPE,
    ASSET_OWNED_BY,
    ASSET_OWNED_BY_WITHOUT_PARTNER,
    STATUS_OF_REALTOR,
    TASK_VENDOR
} from '../../../constants/constatnt-variable'
import CustomInput from '../../../helpers/custom-input'
import CustomInputSideCheckbox from '../../../helpers/custom-input-side-checkbox'
import CustomDropDown from '../../../helpers/dropdown/custom-dropdown'
import { Controller, useForm } from 'react-hook-form'
import CustomRadioChip from '../../../helpers/radio-chip'
import RadioButtonGroup from '../../../helpers/radio-button-group'
import RadioButtonGroupDoc from '../../../helpers/radio-button-group-doc'
import { AssetsCreditValidation, TaskTypeValidation } from '../../../constants/validation-rules'


export default function TaskTypeForm({ handleClose, onSubmit, editItem }) {



    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {

            taskTypeFor: editItem?.taskTypeFor,
            taskTypeDescription: editItem?.taskTypeDescription,
            taskTypeName: editItem?.taskTypeName
        }
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box width={'100%'} my={2} >
                <Box>
                    <Controller
                        name="taskTypeName"
                        control={control}
                        rules={TaskTypeValidation.taskType}
                        render={({ field }) => (
                            <CustomInput {...field} field={field}
                                placeholder={'Task Type'}
                                type={'text'} label={'Enter the task type'} required error={errors?.taskType?.message} />
                        )}
                    />
                    <Controller
                        name="taskTypeDescription"
                        control={control}
                        rules={TaskTypeValidation.taskDescription}
                        render={({ field }) => (
                            <CustomInput {...field} field={field}
                                placeholder={'Enter the instructions for filling the document'}
                                type={'text'} label={'Task Description'} required error={errors?.taskDescription?.message} />
                        )}
                    />

                    <Controller
                        name="taskTypeFor"
                        control={control}
                        rules={TaskTypeValidation.taskFor}
                        render={({ field }) => (
                            <RadioButtonGroupDoc
                                {...field}
                                field={field}
                                required
                                direction="row"
                                labelComponent={<FormLabel>{`Is this task for ?`}</FormLabel>}
                                data={TASK_VENDOR}
                                error={errors?.taskFor?.message}
                            />
                        )}
                    />





                </Box>

                <Box marginY={4} textAlign={'right'}>


                    <Button
                        type="button"
                        onClick={handleClose}

                        sx={{

                            width: '50%',
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        type="submit"
                        variant="contained"
                        sx={{

                            width: '50%',
                        }}
                    >
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button>
                </Box>
            </Box>
        </form>

    )
}
import { ContentCutOutlined } from "@mui/icons-material";
import { API } from "../services/api-config";
import { docPreview, documentPreview, downloadReportFromServer } from "./file-upload-download";

export const previewDocument = (row) => {










    console.log(" Preview Functiion " + JSON.stringify(row, null, 2))
    const storageKey = row?.storageKey;
    const fileName = row?.fileName;
    // const apiUrl = API.downloadDoc
    let apiUrl

    if (row?.docType === 'LocalUpload') {

        console.log(" Local Upload Preview ")
        // not working
        documentPreview(API.proxyDocumentDownload, {
            id: row?.docId,
            serviceType: "task",
        });



    } else {
        console.log(" Doc Library Upload Preview ")
        apiUrl = API.downloadDoc
        if (storageKey && fileName) {


            docPreview(apiUrl, { storageKey, fileName })
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        } else {
            alert('Missing storage key or file name!');
        }

    }


};


export const previewLocalTaskDocument = (row) => {

    console.log(" Preview Functiion " + JSON.stringify(row, null, 2))
    const storageKey = row?.storageKey;
    const fileName = row?.fileName;
    const apiUrl = API.downloadDoc

    if (storageKey && fileName) {
        // Call docPreview to download or preview the document
        docPreview(apiUrl, { storageKey, fileName })
            .then((url) => {
                console.log('Preview URL:', url);
            })
            .catch((error) => {
                console.error('Error previewing the file:', error);
            });
    } else {
        alert('Missing storage key or file name!');
    }
};
// import React from "react";
// import {
//     Box,
//     Button,
//     Stack,
//     RadioGroup,
//     FormControlLabel,
//     Radio,
//     Typography,
//     FormHelperText,
// } from "@mui/material";
// import { Controller, useForm } from "react-hook-form";

// export default function ThirdPartyConfirm({ handleClose, onConfirm }) {
//     const {
//         control,
//         handleSubmit,
//         formState: { errors },
//     } = useForm({
//         defaultValues: {
//             option: "",
//         },
//     });

//     const onSubmit = (data) => {
//         onConfirm(data.option);
//         handleClose();
//     };

//     return (
//         <Box
//             sx={{
//                 // minHeight: '70vh',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//             }}
//         >
//             <Stack spacing={2} sx={{ width: '100%' }}>
//                 <form onSubmit={handleSubmit(onSubmit)}>
//                     <Controller
//                         name="option"
//                         control={control}
//                         rules={{ required: "Please select an option" }}
//                         render={({ field }) => (
//                             <RadioGroup {...field} row>
//                                 <FormControlLabel
//                                     value="RESUMELATER"
//                                     control={<Radio />}
//                                     label="Resume Task Later"
//                                 />
//                                 <FormControlLabel
//                                     value="PARTIALCOMPLETE"
//                                     control={<Radio />}
//                                     label="Send Response and Continue"
//                                 />
//                                 <FormControlLabel
//                                     value="COMPLETE"
//                                     control={<Radio />}
//                                     label="Mark Task as Completed"
//                                 />
//                             </RadioGroup>
//                         )}
//                     />
//                     {errors.option && (
//                         <FormHelperText error>
//                             {errors.option.message}
//                         </FormHelperText>
//                     )}

//                     <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2} mt={2}>
//                         <Button type="submit" variant="contained">
//                             OK
//                         </Button>
//                         <Button variant="text" onClick={handleClose}>
//                             Cancel
//                         </Button>
//                     </Stack>
//                 </form>
//             </Stack>
//         </Box>
//     );
// }
import React from "react";
import {
    Box,
    Button,
    Stack,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { profileState } from "../../recoil/profile-atom";
import { useRecoilState } from "recoil";

export default function ThirdPartyConfirm({ handleClose, onConfirm, isResolve = false }) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            option: "",
        },
    });

    let [profileData, setProfileData] = useRecoilState(profileState);

    const onSubmit = (data) => {
        onConfirm(data.option);
        handleClose();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Stack spacing={2} sx={{ width: '100%' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {!(profileData.role === 'Borrower') && (
                        <Controller
                            name="option"
                            control={control}
                            rules={{ required: "Please select an option" }}
                            render={({ field }) => (
                                <RadioGroup {...field} row>
                                    <FormControlLabel
                                        value="RESUMELATER"
                                        control={<Radio />}
                                        label="Resume Task Later"
                                    />
                                    <FormControlLabel
                                        value="INCOMPLETE"
                                        control={<Radio />}
                                        label="Send Response and Mark it Not Completed"
                                    />
                                    <FormControlLabel
                                        value="RESOLVED"
                                        control={<Radio />}
                                        label="Mark Task as Resolve"
                                    />
                                </RadioGroup>
                            )}
                        />
                    )}
                    {profileData.role === 'Borrower' && (
                        <Controller
                            name="option"
                            control={control}
                            rules={{ required: "Please select an option" }}
                            render={({ field }) => (
                                <RadioGroup {...field} row>
                                    <FormControlLabel
                                        value="RESUMELATER"
                                        control={<Radio />}
                                        label="Resume Task Later"
                                    />
                                    <FormControlLabel
                                        value="INCOMPLETE"
                                        control={<Radio />}
                                        label="Send Response and Continue"
                                    />
                                    <FormControlLabel
                                        value="COMPLETED"
                                        control={<Radio />}
                                        label="Mark Task as Completed"
                                    />
                                </RadioGroup>
                            )}
                        />
                    )}
                    {errors.option && (
                        <FormHelperText error>
                            {errors.option.message}
                        </FormHelperText>
                    )}

                    <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2} mt={2}>
                        <Button type="submit" variant="contained">
                            OK
                        </Button>
                        <Button variant="text" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Stack>
                </form>
            </Stack>
        </Box>
    );
}

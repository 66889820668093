import React, { useContext, useEffect, useState } from "react";
import CustomRadioChip from "../../../../helpers/radio-chip";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch, } from "react-hook-form";
import {
    CONTACT_STATE,
    CREATE,
    TASK_LENDERS_CONDITION,
    TASK_PRIORITY,
    TASK_TYPE_CHOICE,
    UPDATE,
} from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import RadioButtonGroup from "../../../../helpers/radio-button-group-doc";
import {
    getApiCallDynamic,
    postApiCallDynamicWithOutReturn,
    uploadFile,
} from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import {
    DateToTimestapmFormater,
    TimeStampToMonthDateYearForInput,
} from "../../../../helpers/time-formater";
import { currentDate } from "../../../../helpers/common_fuctions/current-date";
import { KSCloudUploadIcon } from "../../../../icons/custome-icon";
import { toastNotify } from "../../../../helpers/alert-msg";
import { TaskTypeValidation, TaskValidation } from "../../../../constants/validation-rules";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import MultiSelectDropDown from "../../../../helpers/dropdown/multi-select-dropdown";


import SupportingDocument from "./supporting-doc";
import PreviewTask from "./preview-task";
import { DocumentDialogCommonHooks } from "../../../../helpers/modal/modal";
import CustomInputMultiLine from "../../../../helpers/custom-input-multiline";
import { TaskContext } from "../../taskContext/TaskContext";







export default function BorrowerTaskForm({
    formId,
    edit = false,
    taskFormData,
    handleClose,
    taskReload,
    taskList,  //Borrower Master Tasks
    taskReceiverDetails,
    info
}) {

    const {
        control,
        handleSubmit,
        formState: { errors }, setValue, getValues
    } = useForm({
        defaultValues: {

        },
    });

    const [apiCallData, setApiCallData] = useState(null);

    const { taskState, updateContextBorrowerTasks, updateContextThirdPartyTasks,

        insertSupportingDoc,
        deleteSupportingDoc,
        updateSupportingDoc,
        clearSupportingDocs,

    } = useContext(TaskContext);

    const { contextSupportingDocs } = taskState;

    const taskTypeOptions = taskList.map((task) => ({
        label: task.taskTypeName,
        value: task.id,
    }));

    const [taskTypeTitle, setTaskTypeTitle] = useState(null)
    const getTaskTypeNameById = (id) => {
        const task = taskList.find((task) => task.id === id);
        return task ? task.taskTypeName : null;
    };


    const { taskType, taskReceiver } = useWatch({ control })

    useEffect(() => {

        if (taskType) {



            setTaskTypeTitle(getTaskTypeNameById(taskType))

        }
    }, [taskType]);





    // useEffect(() => {

    //     if (taskReceiver) {
    //         const matchedReceiver = taskReceiverDetails.find(item => item.name === taskReceiver);
    //         setValue('receiverName', matchedReceiver?.name)
    //         setValue('receiverPhoneNo', matchedReceiver?.phone)
    //         setValue('receiverEmail', matchedReceiver?.email)
    //     } else {
    //         setValue('receiverName', null)
    //         setValue('receiverPhoneNo', null)
    //         setValue('receiverEmail', null)
    //     }
    // }, [taskReceiver]);

    const [titles, setTitles] = useState([])
    const [taskDocs, setTaskDocs] = useState([])
    const [requiredDocs, setRequiredDocs] = useState([])
    const [supportingDocs, setSupportingDocs] = useState([])

    useEffect(() => {

        if (taskType) {
            const path = API.getMasterTaskDocByTaskID() + taskType

            const taskListsData = () => {


                getApiCallDynamic({

                    path


                })
                    .then((res) => {
                        if (res?.status === 200) {
                            const tasks = res?.data || [];
                            setTaskDocs(tasks);


                            setRequiredDocs(res.data)

                            const titles = res?.data
                                .filter(item => item.documentType !== undefined)
                                .map(item => item.documentType);


                            setTitles(titles);

                            // const supportingDocs = res?.data.filter(item => item.blankDocument === true);
                            const supportingDocs = res?.data
                                .filter(item => item.blankDocument === true)
                                .map(({ documentType, ...rest }) => ({
                                    ...rest,
                                    documentName: documentType,
                                }));

                            //setSupportingDocs(supportingDocs);

                            //  const updatedDocs = [...contextSupportingDocs, ...supportingDocs];
                            clearSupportingDocs()
                            if (supportingDocs && supportingDocs.length > 0) {

                                insertSupportingDoc(...supportingDocs);
                            }

                            //   insertSupportingDoc(...supportingDocs)
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error);
                    });
            };

            taskListsData();
        }
    }, [taskType]);




    // console.log(" initial context supporting " + JSON.stringify(contextSupportingDocs, null, 2))





    //const nameObjects = taskReceiverDetails.map(item => ({ label: item.name, value: item.name }));

    // const jsonNameObjects = JSON.stringify(nameObjects, null, 2);


    const formOnSubmit = async (data) => {





        const requiredDocuments = requiredDocs.filter(doc =>
            data?.requiredDocs?.includes(doc.documentType))
            .map(({ id, storageKey, fileName, ...rest }) => ({
                ...rest,
                blankDocStorageKey: storageKey,
                blankDocFileName: fileName,
                blankDocId: id
            }));


        const apiData = {
            //  "id": 123,
            "taskCode": null,
            "taskTypeName": taskTypeTitle,
            "loanCode": null,
            "formId": formId,
            "taskType": {
                "id": data?.taskType,

            },
            "taskDescription": data?.taskDescription,
            "dueDate": data?.dueDate,
            "taskPriority": data?.taskPriority,
            "lenderCondition": data?.isLender === 'Yes' ? true : false,

            requiredDocuments,

            supportingSampleDocuments: contextSupportingDocs,

            "taskStatus": null,
            // "taskReceiverName": data?.receiverName,
            // "taskReceiverPhoneNumber": data?.receiverPhoneNo,
            // "taskReceiverEmail": data?.receiverEmail
        }


        setApiCallData(apiData);

        await postApiCallDynamicWithOutReturn({
            data: apiData,
            path: API.getThirdPartyTask,
            header: edit ? UPDATE : CREATE,
            refresh: taskReload,
        });

        handleClose();
    };

    const [finalClick, setFinalClick] = useState(false);

    const [formData, setFormData] = useState({});
    const { watch } = useForm();



    const handlePreview = (data) => {
        handleSubmit((data) => {
            // If form is valid, open the preview

            const requiredDocuments = requiredDocs.filter(doc =>
                data?.requiredDocs?.includes(doc.documentType))
                .map(({ id, storageKey, fileName, ...rest }) => ({
                    ...rest,
                    blankDocStorageKey: storageKey,
                    blankDocFileName: fileName,
                    blankDocId: id
                }));


            const apiData = {
                //  "id": 123,
                "taskCode": null,
                "taskTypeName": taskTypeTitle,
                "loanCode": null,
                "formId": formId,
                "taskType": {
                    "id": data?.taskType,

                },
                "taskDescription": data?.taskDescription,
                "dueDate": data?.dueDate,
                "taskPriority": data?.taskPriority,
                "lenderCondition": data?.isLender === 'Yes' ? true : false,

                requiredDocuments,

                supportingSampleDocuments: contextSupportingDocs,

                "taskStatus": null,
                //  "taskReceiverName": data?.receiverName,
                //  "taskReceiverPhoneNumber": data?.receiverPhoneNo,
                //  "taskReceiverEmail": data?.receiverEmail
            }


            setApiCallData(apiData);



            setModalOpen(true);


            setIsPreview(true);


        })();
    };

    const handleButtonClick = () => {
        setFinalClick(true);
    };


    const [isPreview, setIsPreview] = useState(false);
    const handleSend = () => {

        handleSubmit(formOnSubmit)();
    };
    const handleClosePreview = () => {
        setModalOpen(false);
    };
    const handleCancelPreview = () => {
        setIsPreview(false);
    };





    const [modalOpen, setModalOpen] = useState(false);




    const [selectAll, setSelectAll] = useState(false);


    // Handle the "Select All" toggle
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        const selectedOptions = isChecked ? titles : [];
        setValue('requiredDocs', selectedOptions);
    };

    const handleChange = (e) => {

        setCharCount(e.target.value.length);
        onChange(e); // Trigger react-hook-form's onChange
    };



    return (
        <>







            {!isPreview ? (
                <Box >

                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="left">
                        <Grid container spacing={2} justifyContent="center">

                            <Grid item xs={12} md={12}>

                                <form onSubmit={handleSubmit(formOnSubmit)}>

                                    <Typography variant="h3" paragraph>Create New Task</Typography>



                                    <Controller
                                        name='taskType'
                                        control={control}
                                        rules={TaskTypeValidation.taskType}
                                        render={({ field }) => (
                                            <CustomDropDown
                                                {...field}
                                                field={field}
                                                label={'Task Type'}
                                                required
                                                state={taskType}
                                                option={taskTypeOptions}
                                                error={errors?.taskType?.message}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="taskDescription"
                                        control={control}
                                        rules={{ required: "Task description is required" }}
                                        render={({ field, fieldState: { error } }) => (
                                            <CustomInputMultiLine
                                                {...field}
                                                //  label="Enter Task Description"
                                                multiline
                                                error={error?.message}
                                            />
                                        )}
                                    />


                                    {/* <Controller
                                name="taskDescription"
                                control={control}
                                rules={{ required: "Task description is required" }}
                                render={({ field, fieldState: { error } }) => (
                                    <CustomInputMultiLine
                                        {...field}
                                        label="Enter Task Description"
                                        multiline
                                        error={error?.message}
                                    />
                                )}
                            /> */}





                                    <Box marginY={4}>

                                        <Typography variant="h3" paragraph>Required Document</Typography>
                                        <Stack direction="column" spacing={2}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectAll}
                                                            onChange={handleSelectAllChange}
                                                        />
                                                    }
                                                    label="Select All"
                                                />
                                            </Box>
                                        </Stack>
                                        <Controller
                                            name='requiredDocs'
                                            control={control}
                                            render={({ field }) => (
                                                <MultiSelectDropDown
                                                    field={field}

                                                    options={titles}
                                                    selectAll={selectAll}
                                                    errors={errors.requiredDocs}
                                                //label="Tag"
                                                />
                                            )}
                                        />

                                    </Box>






                                    <Controller
                                        name="dueDate"
                                        control={control}
                                        rules={TaskValidation.dueDate}
                                        render={({ field }) => (
                                            <CustomInput
                                                {...field}
                                                field={field}
                                                type={"date"}
                                                // maxDate={currentDate()}
                                                label={"Due date"}
                                                required
                                                error={errors?.dueDate?.message}
                                            />
                                        )}
                                    />







                                    <Controller
                                        name="taskPriority"
                                        control={control}
                                        rules={TaskValidation.taskPriority}
                                        render={({ field }) => (
                                            <CustomRadioChip
                                                {...field}
                                                field={field}
                                                required
                                                label={"Task priority"}
                                                data={TASK_PRIORITY}
                                                error={errors?.taskPriority?.message}

                                            />
                                        )}
                                    />





                                    <Controller
                                        name="isLender"
                                        control={control}
                                        rules={TaskValidation.isLender}
                                        render={({ field }) => (
                                            <RadioButtonGroup
                                                {...field}
                                                field={field}
                                                required
                                                direction="row"
                                                labelComponent={<FormLabel>{`Is Lender's Condition?`}</FormLabel>}
                                                data={TASK_LENDERS_CONDITION}
                                                error={errors?.isLender?.message}
                                            />
                                        )}
                                    />





                                    <Grid container>
                                        <Grid item xs={12} md={12} marginY={4}>
                                            <Typography variant="h3" paragraph>
                                                Supporting Documents
                                            </Typography>

                                            <SupportingDocument
                                                supportingDocs={contextSupportingDocs}
                                                setSupportingDocs={setSupportingDocs}
                                                formId={formId}
                                                documentTypes={titles}
                                            />
                                        </Grid>
                                    </Grid>



                                    <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
                                        <Button
                                            variant="contained"
                                            onClick={() => handlePreview(getValues())}
                                            disabled={false}
                                        >
                                            Preview
                                        </Button>
                                        <Button variant="text" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </form>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

            ) : (


                <DocumentDialogCommonHooks
                    show={modalOpen}
                    handleClose={handleClose}
                    // title={`Add Task Type`}
                    size={'lg'}
                    body={
                        <PreviewTask
                            isPreview={true}
                            formData={apiCallData}
                            handleSend={handleSend}
                            handleCancelPreview={handleCancelPreview}
                            info={info}
                            contextSupportingDocs={contextSupportingDocs}
                        />
                    }
                />



            )}



        </>
    );
}

import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'

const TaskSuccessPage = ({ handleResolve }) => {





    return (
        <>

            <Container maxWidth={'lg'} >

                <Box sx={{ minHeight: '50vh' }} mt={3}>
                    <Typography variant="h3" gutterBottom>
                        Task Update
                    </Typography>
                    <Typography >
                        Your task has been updated. You can resume or view it by clicking the &#39;Resolve&#39; button.
                    </Typography>
                    <Button variant="contained" onClick={handleResolve} sx={{ mt: 2 }}>
                        Resolve
                    </Button>
                </Box>

            </Container>

        </>

    )
}

export default TaskSuccessPage
import { API } from "../../services/api-config";
import { getApiCallDynamic, getApiCallDynamicWithHeader } from "../../services/api-service";

export const fetchBorrowerDetails = async (taskCode) => {




    try {
        const res = await getApiCallDynamic({
            path: API.getThirdPartyTask,
            param: { thirdPartyTaskCode: taskCode },
            //  token: accessCode
        });

        if (res?.status === 200) {
            console.error('Ftech data :', res);
            //setThirdPartyTaskDetails(res.data);
        } else {
            console.error('Unexpected response status:', res);
        }

        return res?.data;
    } catch (error) {
        console.error('Error fetching third-party details:', error);
    }
};



export const getTaskStatusLabel = (status) => {
    switch (status) {
        case "COMPLETED":
            return "Completed";
        case "RESOLVED":
            return "Resolved";
        case "CREATED":
        case "INCOMPLETE":
        case "RESUMELATER":
            return "Active";
        default:
            return status;
    }
};



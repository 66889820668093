import { Box, Button, Container, Grid, IconButton, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { KSUploadIcon, KSUploadIconDoc } from "../../icons/custome-icon"
import { API } from "../../services/api-config";
import { toastNotify } from "../../helpers/alert-msg";
import { postApiCallDynamicWithResponse, uploadFile, uploadFileWithToken } from "../../services/api-service";
import ThirdPartyUploadCard from "./third-party-upload-card";
import { KSCloudDownloadIcon, KSDocumentDownloadIcon, KSDocumentSearchIcon } from '../../icons/custome-icon'
import { docPreview, docPreviewResolve, docPreviewWithToken } from "../../helpers/file-upload-download";
import { previewLocalTaskDocument } from "../../helpers/preview-document";
import ThirdPartyChatDisplay from "./thirdparty-chat-display";
import ThirdPartyResolveConfirm from "./third-party-resolve-confirm";
import { DocumentDialogCommonHooks } from "../../helpers/modal/modal";
import { UPDATE } from "../../constants/constatnt-variable";


function ThirdPartyResolvePreviewTask({
    formData, handleCancelPreview, info,
    thirdPartyTaskDetails,
    accessCode,
    taskCode,
    setUploadFile,
    taskReload
}) {


    const [listDocumentTypes, setListDocumentTypes] = useState([])
    const [listSupportingDocs, setListSupportingDocs] = useState([])
    const [infoData, setInfoData] = useState(null)
    console.log("formDataformData" + JSON.stringify(formData, null, 2))
    useEffect(() => {


        setListDocumentTypes(formData?.requiredDocuments)
        //  setListDocumentTypes(formData?.requiredDocuments.map(doc => doc.documentType));
        // setListSupportingDocs(formData?.supportingSampleDocuments?.map(doc => doc.docType))
        setListSupportingDocs(formData?.supportingSampleDocuments)
        const info = {

        };



        setInfoData(info)
    }, [formData]);


    console.log(" beta " + JSON.stringify(listDocumentTypes, null, 2))
    function capitalize(name) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }






    const [localFileDetails, setLocalFileDetails] = useState(null);
    let [localData, setLocalData] = useState(null)




    const fileUpload = async (event, item) => {
        event.preventDefault(); // Prevents page reload


        let file = event.target.files[0];
        if (file) {
            const res = await uploadFileWithToken({
                formId: formData?.formId,
                // docId: taskFormData?.brokerDocId ? taskFormData?.brokerDocId : null,
                file: file,
                serviceType: "task",
                path: API.proxyDocumentThirdPartyOthersUpload,
                token: accessCode
            });

            if (res.status === 201) {
                toastNotify("success", res.data.message);
                setLocalFileDetails(res.data);

                // Retrieve form values after successful upload
                // if (documentType && documentName) {
                //     const formData = {
                //         documentType,
                //         documentName,
                //         ...res.data,
                //     };
                //     setLocalData(formData);
                //     //  handleClose();
                // } else {
                //     toastNotify("error", "Document type or name is missing.");
                // }
            } else {
                toastNotify("error", res.data.message);
            }
        } else {
            console.log("not found");
        }
    };




    const handleView = (row) => {
        console.log(" betarow" + JSON.stringify(row, null, 2))
        console.log('Viewing document with ID:', JSON.stringify(row, null, 2));
        if (row?.docType === 'LocalUpload') {

            console.log(" Loacl Upload Preview")
            const storageKey = row?.storageKey;
            const fileName = row?.fileName;
            const apiUrl = API.downloadDocResolve
            const token = accessCode
            const docId = row?.docId

            const data = {

                storageKey: storageKey,
                //fileName: fileName,
                fileName: docId,// in case local upload 
                thirdPartyTaskCode: taskCode
            }

            if (storageKey && fileName) {
                // Call docPreview to download or preview the document
                docPreviewWithToken(apiUrl, data, token)
                    .then((url) => {
                        console.log('Preview URL:', url);
                    })
                    .catch((error) => {
                        console.error('Error previewing the file:', error);
                    });
            } else {
                alert('Missing storage key or file name!');
            }

        } else {

            console.log(" Existing Preview")

            const storageKey = row?.storageKey;
            const fileName = row?.fileName;
            const apiUrl = API.downloadDocResolve
            const token = accessCode
            //const docId = row?.docId

            const data = {

                storageKey: storageKey,
                fileName: fileName,
                // fileName: docId,// in case local upload 
                thirdPartyTaskCode: taskCode
            }

            if (storageKey && fileName) {
                // Call docPreview to download or preview the document
                docPreviewWithToken(apiUrl, data, token)
                    .then((url) => {
                        console.log('Preview URL:', url);
                    })
                    .catch((error) => {
                        console.error('Error previewing the file:', error);
                    });
            } else {
                alert('Missing storage key or file name!');
            }
        }
    };




    const handleDownload = (id) => {
        // Call your API to download the document
        console.log('Downloading document with ID:', id);
        // Example API call
        // axios.get(`/api/documents/download/${id}`, { responseType: 'blob' });
    };






    const [resolveModal, setResolveModal] = useState(false);

    const handleResolvekModalOpen = () => {
        setResolveModal(true);
    };

    const handleResolveModalClose = () => {
        setResolveModal(false);
    };

    const handleSend = () => {
        handleResolvekModalOpen()
    };
    const handleResolveConfirm = async (selectedOption) => {

        let data;

        console.log(" Third party data " + JSON.stringify(thirdPartyTaskDetails, null, 2))



        data = {
            ...formData,
            taskStatus: selectedOption,
        };

        try {


            const res = await postApiCallDynamicWithResponse({
                path: API.createThirdPartyTask,
                data: data,
                // token: accessCode,
                header: UPDATE
            });

            console.log("Final Submit", JSON.stringify(data?.requiredDocuments, null, 2));
            if (res) {

                // setModalOpen(true);
                //  handleCancelPreview()
                //localStorage.removeItem('accessCode');
                //  setEmailOtp('');
                //  setPhoneOtp('');
                // localStorage.setItem('isSecondary', true)
                // setIsSubmit(true)
                handleCancelPreview()
                taskReload()

            }
        } catch (error) {
            console.error("Error during OTP validation:", error);
            throw error;
        }








    }




















    return (<>
        <Container maxWidth={'lg'}>




            <Box >

                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="left">
                    <Box mr={2}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                <KSUploadIconDoc width={42} height={42} />
                            </Typography>

                        </Stack>

                    </Box>

                    <Box style={{ display: 'flex', flexDirection: 'column', padding: 0, }}>

                        <Box>

                            <Typography variant="h6"> </Typography>

                            <Grid item xs={12} md={6} mb={3}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                        {formData?.taskType?.taskTypeName}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px', lineHeight: '18px', fontWeight: 400, color: '#2F80ED' }}>
                                        {formData?.taskPriority}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid container spacing={0} sx={{ flexGrow: 1, alignItems: "center" }}>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                        {/* <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Borrower Name:
                                </Typography>
                                <Typography variant="body1">
                                    {formData?.borrowerFirstName}{formData?.borrowerLastName}
                                </Typography> */}
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Borrower Name:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.borrowerFirstName && capitalize(formData.borrowerFirstName)}{' '}
                                            {formData?.borrowerLastName && capitalize(formData.borrowerLastName)}
                                        </Typography>

                                    </Stack>

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            MLO Name:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.mloFirstName && capitalize(formData?.mloFirstName)}{' '}
                                            {formData?.mlolastName && capitalize(formData?.mlolastName)}

                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} mt={2} mb={2}>

                                    <Stack
                                        direction="row"
                                        alignItems="flex-start" // Aligns items to the start of the row
                                        justifyContent="space-between"
                                        spacing={1}
                                        sx={{ width: '80%' }}
                                    >
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Property:
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{ textAlign: 'right' }} // Aligns the property details to the right
                                        >
                                            <Typography variant="body1" sx={{ textAlign: 'right' }}>
                                                {[
                                                    formData?.propertyAddress?.addressLineText,
                                                    formData?.propertyAddress?.addressAdditionalLineText,
                                                    formData?.propertyAddress?.cityName,
                                                    formData?.propertyAddress?.addressUnitIdentifier,
                                                    formData?.propertyAddress?.county,
                                                    formData?.propertyAddress?.postalCode,
                                                    formData?.propertyAddress?.stateCode,
                                                    formData?.propertyAddress?.countryCode,
                                                ]
                                                    .filter(Boolean)
                                                    .join(', ')
                                                }
                                            </Typography>


                                        </Typography>
                                    </Stack>


                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>

                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Loan Amount:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.loanAmount
                                                ? `$ ${new Intl.NumberFormat('en-US').format(formData?.loanAmount)}`
                                                : ''}
                                        </Typography>

                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>

                                </Grid>
                                <Grid item xs={12} md={6} mb={2}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Due Date:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.dueDate}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} mt={2} mb={2}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Loan Type:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.loanType}
                                        </Typography>
                                    </Stack>

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Is lender Condition ?
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.lenderCondition === true ? 'Yes' : 'No'}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid sx={12} md={12} mt={2} mb={4}>
                                    <Typography>{formData?.taskDescription}</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} mt={4} mb={2}>
                                {(listSupportingDocs && listSupportingDocs.length > 0) ? (
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Supporting Documents
                                        </Typography>
                                    </Stack>
                                ) : null}
                            </Grid>



                            {/* <List>
                                {listSupportingDocs?.map((item, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={`${index + 1}. ${item}`} />
                                    </ListItem>
                                ))}
                            </List> */}
                            {/* <List>
                                {listSupportingDocs?.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <>
                                             
                                                <IconButton
                                                    edge="end"
                                                    aria-label="view"
                                                    color="primary"
                                                    onClick={() => handleView(item)}
                                                >
                                                    <KSDocumentSearchIcon />
                                                </IconButton>

                                           
                                                <IconButton
                                                    edge="end"
                                                    aria-label="download"
                                                    color="primary"
                                                    onClick={() => handleDownload(item)}
                                                >
                                                    <KSCloudDownloadIcon />
                                                </IconButton>
                                            </>
                                        }
                                    >
                                        <ListItemText primary={`${index + 1}. ${item?.documentName}`} />
                                    </ListItem>
                                ))}

                            </List> */}
                            <List>
                                <Grid container spacing={2}>
                                    {listSupportingDocs?.map((item, index) => (
                                        <Grid item xs={12} sm={6} key={index}> {/* xs=12 for full width on small screens, sm=6 for half width on medium+ screens */}
                                            <Box
                                                sx={{
                                                    padding: '10px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '5px',
                                                    boxSizing: 'border-box',
                                                    width: '100%',
                                                }}
                                            >
                                                <ListItem
                                                    secondaryAction={
                                                        <>
                                                            {/* IconButton for view */}
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="view"
                                                                color="primary"
                                                                onClick={() => handleView(item)}
                                                            >
                                                                <KSDocumentSearchIcon />
                                                            </IconButton>

                                                            {/* IconButton for download */}
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="download"
                                                                color="primary"
                                                                onClick={() => handleDownload(item)}
                                                            >
                                                                <KSCloudDownloadIcon />
                                                            </IconButton>
                                                        </>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body1" fontWeight="bold">
                                                                {`${index + 1}. ${item?.documentName}`}
                                                            </Typography>
                                                        }
                                                        secondary={item?.fileName} // Display the filename below the document name
                                                    />
                                                </ListItem>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </List>


                            <Grid item xs={12} md={12} mt={4} mb={2}>
                                {(listDocumentTypes && listDocumentTypes.length > 0) ? (
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Required Documents
                                        </Typography>
                                    </Stack>
                                ) : null}
                            </Grid>


                            {/* <List>
                        {listDocumentTypes.map((item, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={`${index + 1}. ${item}`} />
                            </ListItem>
                        ))}
                    </List> */}

                            {/* <List>
                        {listDocumentTypes.map((item, index) => (
                            // <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            //     <ListItemText primary={`${index + 1}. ${item}`} />


                            //     <Button
                            //         component="label"
                            //         size="small"
                            //         startIcon={<KSCloudUploadIcon color={"primary"} />}
                            //     //  disabled={isUploadDisabled} // Disable the button based on the condition
                            //     >
                            //         <input
                            //             //  onChange={fileUpload}
                            //             onChange={(e) => fileUpload(e, item)}
                            //             hidden
                            //             accept="image/*"
                            //             type="file"
                            //         />
                            //         {`Upload`}
                            //     </Button>
                            // </ListItem>

                            <ThirdPartyUploadCard
                                key={index}
                                item={item}
                                accessCode={accessCode}
                            />
                        ))}
                    </List> */}

                            <List>
                                {listDocumentTypes?.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <>
                                                {/* IconButton for view */}
                                                <IconButton
                                                    edge="end"
                                                    aria-label="view"
                                                    color="primary"
                                                    onClick={() => handleView(item)}
                                                >
                                                    <KSDocumentSearchIcon />
                                                </IconButton>

                                                {/* IconButton for download */}
                                                <IconButton
                                                    edge="end"
                                                    aria-label="download"
                                                    color="primary"
                                                    onClick={() => handleDownload(item)}
                                                >
                                                    <KSCloudDownloadIcon />
                                                </IconButton>
                                            </>
                                        }
                                    >
                                        <ListItemText primary={`${index + 1}. ${item?.documentType}`} />
                                    </ListItem>
                                ))}

                            </List>
                            {/* <List>
                                {listDocumentTypes?.map((item, index) => (
                                    <ThirdPartyUploadCard
                                        key={item.id}
                                        item={item}
                                        accessCode={accessCode}
                                        fileUpload={fileUpload}
                                        formId={formData?.formId}
                                        setUploadFile={setUploadFile}
                                        taskCode={taskCode}
                                    />
                                ))}
                            </List> */}
                            <ThirdPartyChatDisplay taskCode={taskCode} />

                            <Grid container spacing={2} mt={3} mb={3}>
                                <Grid item xs={12} md={6}>

                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" gap={2} justifyContent="flex-end">

                                        <Button variant="text" onClick={handleCancelPreview}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" onClick={handleSend}>
                                            Submit
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>


                        </Box >
                    </Box>
                </Box>
            </Box>
        </Container>
        <DocumentDialogCommonHooks
            show={resolveModal}
            handleClose={handleResolveModalClose}
            body={
                <ThirdPartyResolveConfirm
                    handleClose={handleResolveModalClose}
                    onConfirm={handleResolveConfirm}
                />
            }
        />
    </>
    );
}

export default ThirdPartyResolvePreviewTask;
